import { axiosInstance } from "../utils/AxiosInstance";
import { handleError, handleResponse } from "./apiResponse";

// fetch all account heads
export function fetchAllAccount() {
  return axiosInstance()
    .get("/transactions/account/account-heads/all")
    .catch(handleError);
}

// fetch all parent accounts
export function fetchAllParentAccount() {
  return axiosInstance()
    .get("/transactions/account/account-heads/parents")
    .catch(handleError);
}

// fetch leaf account // child accounts
export function fetchLeafAccount() {
  return axiosInstance()
    .get("/transactions/account/account-heads/leafs")
    .catch(handleError);
}

// fetch leaf account by code
export function fetchLeafAccountByCode(code) {
  return axiosInstance()
    .get(`/transactions/account/account-heads/leafs/type-code?code=${code}`)
    .catch(handleError);
}

// fetch all account heads with pagination
export function fetchAllAccountWithPagination(pageable) {
  return axiosInstance()
    .get(
      `/transactions/account/account-heads/all-paginated/pageable=${pageable}`
    )
    .catch(handleError);
}

// fetch transactionable account heads
export function fetchTransactionableAccount() {
  return axiosInstance()
    .get("/transactions/account/account-heads/transactinable")
    .catch(handleError);
}

// fetch transactionable branch account heads
export function fetchTransactionableBranchAccount() {
  return axiosInstance()
    .get("/transactions/account/account-heads/branch/transactinable")
    .catch(handleError);
}

// fetch account head by type
export function fetchAccountByType(type) {
  return axiosInstance()
    .get(`/transactions/account/account-heads/type?type=${type}`)
    .catch(handleError);
}

// fetch branch account by type
export function fetchBranchAccountByType(type) {
  return axiosInstance()
    .get(`/transactions/account/account-heads/branch/type?type=${type}`)
    .catch(handleError);
}

// fetch account head by id
export function fetchAccountById(id) {
  return axiosInstance()
    .get(`/transactions/account/account-heads?id=${id}`)
    .catch(handleError);
}

// add account head
export function addAccountHead(data) {
  return axiosInstance()
    .post("/transactions/account/account-heads", data)
    .then(handleResponse)
    .catch(handleError);
}

// update account head
export function updateAccountHead(data) {
  return axiosInstance()
    .put("/transactions/account/account-heads", data)
    .then(handleResponse)
    .catch(handleError);
}

// delete account head
export function deleteAccountHead(id) {
  return axiosInstance()
    .delete(`/transactions/account/account-heads?id=${id}`)
    .then(handleResponse)
    .catch(handleError);
}

// fetch next account code
export function fetchNextCodeById(id) {
  return axiosInstance()
    .get(`/transactions/account/account-heads?id=${id}`)
    .catch(handleError);
}

// fetch all leaf account head
export function fetchAllLeafAccount() {
  return axiosInstance()
    .get("/transactions/account/account-heads/leaves")
    .catch(handleError);
}

// fetch leaf account head by id
export function fetchLeafAccountById(id) {
  return axiosInstance()
    .get(`/transactions/account/account-heads/leaves/id?id=${id}`)
    .catch(handleError);
}

// fetch account head by code
export function fetchAccountByCode(code) {
  return axiosInstance()
    .get(`/transactions/account/account-heads/code?code=${code}`)
    .catch(handleError);
}

// fetch account head by alias
export function fetchAccountByAlias(alias) {
  return axiosInstance()
    .get(`/transactions/account/account-heads/alias?alias=${alias}`)
    .catch(handleError);
}

// fetch account head by name
export function fetchAccountByName(name) {
  return axiosInstance()
    .get(`/transactions/account/account-heads/name?name=${name}`)
    .catch(handleError);
}

// fetch branch account head by alias
export function fetchBranchAccountByAlias(alias) {
  return axiosInstance()
    .get(`/transactions/account/account-heads/branch/alias?alias=${alias}`)
    .catch(handleError);
}

// fetch branch account head by name
export function fetchBranchAccountByName(name) {
  return axiosInstance()
    .get(`/transactions/account/account-heads/branch/name?name=${name}`)
    .catch(handleError);
}

// fetch account head by parent
export function fetchAccountByParent(parentId) {
  return axiosInstance()
    .get(`/transactions/account/account-heads/parent?parentId=${parentId}`)
    .catch(handleError);
}

// fetch account head by parent
export function findAccountType() {
  return axiosInstance()
    .get(`/transactions/account/account-heads/cv/acc-type`)
    .catch(handleError);
}

// fetch account head by parent
export function findMappingPurpose() {
  return axiosInstance()
    .get(`/transactions/account/account-heads/cv/mapping-purpose`)
    .catch(handleError);
}

export function getBankMapping() {
  return axiosInstance()
    .get(`/transactions/account/account-heads/mapping/bank`)
    .catch(handleError);
}

export function getDayCloseSummary() {
  return axiosInstance()
    .get(`/transactions/account/journal-vouchers/day-close-summary`)
    .catch(handleError);
}
