import { axiosInstance } from "../utils/AxiosInstance";
import { handleError } from "./apiResponse";

export const addMembers = (data) => {
  return axiosInstance()
    .post(`/transactions/admin/members`, data)
    .catch(handleError);
};

export const getCustomers = () => {
  return axiosInstance()
    .get(`/transactions/admin/members/all-customers`)
    .catch(handleError);
};

export const getVendors = () => {
  return axiosInstance()
    .get(`/transactions/admin/members/all-vendors`)
    .catch(handleError);
};

export const getStaffs = () => {
  return axiosInstance()
    .get(`/transactions/admin/members/all-staffs`)
    .catch(handleError);
};
