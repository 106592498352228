import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getActiveWorkingDay } from "../../api/DateStatus";
import InsideHeader from "../../layout/InsideHeader";
import CloseSession from "./CloseSession";
import StartSession from "./StartSession";

function Session() {
  const [active, setActive] = useState();
  const { working_day } = useSelector((state) => state.auth);

  useEffect(() => {
    getActiveWorkingDay().then((res) => {
      setActive(res?.data?.dayStartDateBS);
    });
  }, []);

  return (
    <div>
      <InsideHeader title="Session">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link color="inherit" href="/">
            Home
          </Link>

          <Typography color="textPrimary">Session</Typography>
        </Breadcrumbs>{" "}
      </InsideHeader>
      {working_day ? (
        <CloseSession active={working_day} setActive={setActive} />
      ) : (
        <StartSession />
      )}
    </div>
  );
}

export default Session;
