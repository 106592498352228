import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { FormHelperText, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: 8,
    "& .MuiAutocomplete-inputRoot": {
      background: theme.palette.background.main,
      borderRadius: theme.spacing(0.5),
      padding: "4px",
    },

    "& .MuiTextField-root": {
      marginTop: theme.spacing(2),
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },

    "& .MuiInputLabel-outlined": {
      marginTop: "-26px",
      fontSize: "20px",
      marginLeft: "-10px",
    },

    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, 3px) scale(.75)",
    },
  },
}));

export const SearchableSelectKeys = ({
  name,
  control,
  type = "text",
  errors,
  keys,
  watch,
  placeholder = null,
  label = "",
  options,
  fullWidth = true,
  rule = { required: false },
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Controller
          name={name}
          key={name}
          control={control}
          render={({ field: { onChange, value } }) => {
            console.log(value, "value");
            return (
              <Autocomplete
                id="combo-box-demo"
                key={keys}
                options={options}
                getOptionLabel={(option) => option.label}
                onChange={(e, data) => onChange(data?.value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth={fullWidth}
                    label={label}
                    value={value}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                )}
              />
            );
          }}
          rules={rule}
        />
        {/* {errors[name] && errors[name].type === "required" && (
          <FormHelperText
            style={{ color: "red" }}
          >{`${name} is required`}</FormHelperText>
        )} */}
      </div>
    </>
  );
};
