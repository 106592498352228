import React, { Suspense } from "react";
import { Route, Switch } from "react-router";
import DashboardLayout from "../../layout/DashboardLayout";
import PageNotFoundRoute from "../../navigation/PageNotFound";
import routes from "../../navigation/routes";
function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
}
export default function DashboardIndex() {
  return (
    <>
      <DashboardLayout>
        <Suspense fallback={<h4>Load ... </h4>}>
          <Switch>
            {routes.dashboard.map((route, index) => (
              <RouteWithSubRoutes key={index} {...route} />
            ))}
            <PageNotFoundRoute />
          </Switch>
        </Suspense>
      </DashboardLayout>
    </>
  );
}
