import React, { useEffect, useRef, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Grid } from "@material-ui/core";
import { useStyles } from "./styles";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { DownloadTableExcel } from "react-export-table-to-excel";

const columns1 = [
  { id: "code_no", label: "Code Number", minWidth: 170 },
  { id: "code", label: "Particular", minWidth: 100 },
  {
    id: "population",
    label: "Expense Amount",
    minWidth: 170,
    align: "right",
  },
];
const columns2 = [
  { id: "code_no", label: "Code Number", minWidth: 170 },
  { id: "code", label: "Particular", minWidth: 100 },
  {
    id: "population",
    label: "Income Amount",
    minWidth: 170,
    align: "right",
  },
];

function ViewProfitLoss({ profitLoss, dates }) {
  const classes = useStyles();
  const [drRows, setDrRows] = useState([]);
  const [crRows, setCrRows] = useState([]);
  const [newCrRow, setNewCrRow] = useState([]);
  const [newDrRow, setNewDrRow] = useState([]);
  const doc = new jsPDF();
  const name = localStorage.getItem("name");
  const address = localStorage.getItem("address");
  const tableRef = useRef(null);

  const handlePrint = () => {
    doc.autoTable({
      html: "#first-table",

      startY: 28,

      margin: { top: 28 },
      styles: { overflow: "linebreak" },
      bodyStyles: { valign: "top" },
      columnStyles: { email: { columnWidth: "wrap" } },
      headStyles: {
        overflow: "linebreak",
        halign: "center",
      },
      theme: "striped",
      showHead: "everyPage",

      didDrawPage: function (data) {
        // Header
        doc.setFontSize(18);
        doc.setTextColor(40);
        doc.text(name, 100, 10, { align: "center" });
        doc.setFontSize(12);
        doc.text(address, 100, 16, { align: "center" });
        doc.setFontSize(15);
        doc.text(
          `Profit & Loss - From: ${dates?.from} To: ${dates.to}`,
          100,
          22,
          {
            align: "center",
          }
        );

        // Footer
        var str = "Page " + doc.internal.getNumberOfPages();

        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
    });
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  };

  useEffect(() => {
    setDrRows(profitLoss?.filter((item) => item.drParticulars));
    setCrRows(profitLoss?.filter((item) => item.crParticulars));
  }, [profitLoss]);

  useEffect(() => {
    setNewCrRow([]);
    setNewDrRow([]);
    if (crRows.length > drRows.length) {
      const blankDr = [...Array(crRows.length - drRows.length).keys()].map(
        (index) => index
      );
      setNewDrRow(blankDr);
    }
    if (crRows.length < drRows.length) {
      const blankCr = [...Array(drRows.length - crRows.length).keys()].map(
        (index) => index
      );
      setNewCrRow(blankCr);
    }
    setNewDrRow((prev) => [...prev, ...drRows]);
    setNewCrRow((prev) => [...prev, ...crRows]);
  }, [crRows, drRows]);

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <div className={classes.headerWrapper}>
          <div className={classes.tableHeader}>
            Profit Loss Report {` from ${dates?.from} to ${dates?.to}`}
          </div>
          <button onClick={handlePrint} style={{ marginRight: "130px" }}>
            Print
          </button>
          <DownloadTableExcel
            filename={"Profit Loss - From " + dates?.from + " To " + dates?.to}
            sheet="Profit Loss"
            currentTableRef={tableRef.current}
          >
            <button> Export excel </button>
          </DownloadTableExcel>
        </div>
        {/* <Grid container justifyContent="space-around">
          <Grid item className={classes.headerTitle} sm={6}>
            Expenses
          </Grid>
          <Grid item className={classes.headerTitle} sm={6}>
            Incomes
          </Grid>
        </Grid> */}
        <Grid container>
          <Grid item sm={12}>
            <Table
              stickyHeader
              aria-label="sticky table"
              id="first-table"
              ref={tableRef}
            >
              <TableHead>
                <TableRow>
                  {columns1.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  {columns2.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(newCrRow.length > 0 || newDrRow.length > 0) &&
                  [...Array(newCrRow?.length).keys()].map((index) => (
                    <TableRow
                      hover
                      width={100}
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      className={[
                        index + 1 === newCrRow?.length && classes.lastRow,
                        classes.tableRow
                      ]}
                    >
                      <TableCell className={newDrRow[index]?.drBoldFlag === true && classes.boldRow}> {newDrRow[index]?.drCode}</TableCell>
                      <TableCell className={newDrRow[index]?.drBoldFlag === true && classes.boldRow}> {newDrRow[index]?.drParticulars}</TableCell>
                      <TableCell style={{ textAlign: "right" }} className={newDrRow[index]?.drBoldFlag === true && classes.boldRow}> {newDrRow[index]?.drAmt}</TableCell>
                      <TableCell className={newCrRow[index]?.crBoldFlag === true && classes.boldRow}> {newCrRow[index]?.crCode}</TableCell>
                      <TableCell className={newCrRow[index]?.crBoldFlag === true && classes.boldRow}> {newCrRow[index]?.crParticulars}</TableCell>
                      <TableCell style={{ textAlign: "right" }} className={newCrRow[index]?.crBoldFlag === true && classes.boldRow}> {newCrRow[index]?.crAmt}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </TableContainer>
    </Paper>
  );
}

export default ViewProfitLoss;
