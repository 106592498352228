import { Box, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomInput } from "../common/customInput/CustomInput";
import { CustomSelect } from "../common/customSelect/CustomSelect";
import * as yup from "yup";
import { getUsers, userRegister } from "../../api/Authapi";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
  },
}));

function CreateUser({ handleLoading, setCustomer }) {
  const classes = useStyles();

  const defaultValues = {
    name: "",
    email: "",
    address: "",
    contactNo: "",
    gender: "MALE",
    username: "",
    password: "",
    status: "Active",
    confirm_password: "",
    roleId: 1,
    designationId: 1,
    brId: 1,
    firstLogin: true,
  };

  const validationSchema = yup.object({
    email: yup.string().email().required("Email is required"),
    username: yup
      .string("Enter your Username")
      .required("Username is required"),
    password: yup
      .string("Enter your password")
      .required("Password is required"),
    confirm_password: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const handleSuccess = () => {
    getUsers().then((res) => setCustomer(res.data));
  };
  const onSubmit = (values) => {
    handleLoading(true);
    userRegister(values)
      .then((res) => {
        reset();
        handleSuccess();
        handleLoading(false);
      })
      .catch(() => {
        handleLoading(false);
      });

    console.log(values);
  };

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item sm={3}>
            <CustomInput
              label="Member Name"
              control={control}
              errors={errors}
              name="name"
            />
          </Grid>
          <Grid item sm={3}>
            <CustomInput
              label="Email"
              control={control}
              type="email"
              errors={errors}
              name="email"
            />
          </Grid>
          <Grid item sm={3}>
            <CustomInput
              label="Address"
              control={control}
              errors={errors}
              name="address"
            />
          </Grid>
          <Grid item sm={3}>
            <CustomInput
              label="Contact"
              control={control}
              errors={errors}
              name="contactNo"
            />
          </Grid>
          <Grid item sm={3}>
            <CustomSelect
              label="Gender"
              control={control}
              errors={errors}
              name="gender"
              options={[
                { label: "Male", value: "MALE" },
                { label: "Female", value: "FEMALE" },
              ]}
            />
          </Grid>
          <Grid item sm={3}>
            <CustomInput
              label="Username"
              control={control}
              errors={errors}
              name="username"
            />
          </Grid>
          <Grid item sm={3}>
            <CustomInput
              label="Password"
              control={control}
              errors={errors}
              name="password"
              type="password"
            />
          </Grid>
          <Grid item sm={3}>
            <CustomInput
              label="Confirm Password"
              control={control}
              errors={errors}
              name="confirm_password"
              type="password"
            />
          </Grid>

          <Grid item sm={12}>
            <Box
              marginTop={2}
              marginLeft={5}
              display="flex"
              justifyContent="flex-end"
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
              >
                Add User
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default CreateUser;
