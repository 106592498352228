import clsx from "clsx";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItems from "./listItems";
import List from "@material-ui/core/List";
import Drawer from "@material-ui/core/Drawer";

const drawerWidth = 120;

const useStyles = makeStyles((theme, open) => ({
  sidebar: {
    position: "fixed",
    boxShadow: "0px 7px 12px #d1cfcf",
    zIndex: "5",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: (open) => (open ? "space-between" : "center"),
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  hide: {
    display: "none",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: "transparent",
    border: "none",
    overflowY: "visible",
    // boxShadow: "0px 2px 4px #dcdcdc, 0px 2px 20px #dcd9d970",
  },
  menuButtonHidden: {
    display: "none",
  },

  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(0),
    },
  },
}));

export default function Sidebar({ open, setOpen }) {
  const classes = useStyles(open);

  return (
    <Drawer
      variant="permanent"
      elevation={3}
      className={classes.sidebar}
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <List>
        <ListItems />
      </List>
    </Drawer>
  );
}
