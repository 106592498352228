import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import InsideHeader from "../../layout/InsideHeader";

import JournalEntry from "./JournalEntry";
import ViewJournal from "./ViewJournal";
import { fetchAllAccount, fetchLeafAccount } from "../../api/AccountApi";
import { motion } from "framer-motion";

function JVEntry() {
  const [journals, setJournals] = useState([]);
  const [account, setAccount] = useState([]);
  const [error, setError] = useState("");
  useEffect(() => {
    fetchLeafAccount().then((res) => setAccount(res.data));
  }, []);
  return (
    <div>
      <InsideHeader title="Journal Voucher Entry">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link color="inherit" href="/">
            Dashboard
          </Link>

          <Typography color="textPrimary">Journal Voucher Entry</Typography>
        </Breadcrumbs>{" "}
      </InsideHeader>
      <motion.div
        initial={{ opacity: 0, x: "-55%", scale: 0 }}
        animate={{
          scale: 1,
          opacity: 1,
          x: 0,
        }}
        transition={{ duration: 0.5 }}
      >
        <JournalEntry
          setJournals={setJournals}
          account={account}
          setError={setError}
        />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: "-55%", scale: 0 }}
        animate={{
          scale: 1,
          opacity: 1,
          x: 0,
        }}
        transition={{ duration: 0.5 }}
      >
        <ViewJournal
          journals={journals}
          account={account}
          setJournals={setJournals}
          setError={setError}
          error={error}
        />
      </motion.div>
    </div>
  );
}

export default JVEntry;
