import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { getCurrentDate } from "../../utils/TimestamptoDate";
import { CustomInput } from "../common/customInput/CustomInput";
import { CustomNepaliDatePicker } from "../common/nepalidatepicker/NepaliDatePicker";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
}));

function DateForm({ setFilterData }) {
  const classes = useStyles();
  const defaultValues = {
    fromDate: getCurrentDate(),
    toDate: getCurrentDate(),
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues });

  const onSubmit = (values) => {
    setFilterData(values);
  };
  useEffect(() => {
    setFilterData([]);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container spacing={4}>
        <Grid item sm={3}>
          <CustomNepaliDatePicker
            label="From"
            control={control}
            errors={errors}
            name="fromDate"
          />
        </Grid>
        <Grid item sm={3}>
          <CustomNepaliDatePicker
            label="To"
            name="toDate"
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid item sm={2}>
          <Box marginLeft={5} marginTop={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              type="submit"
            >
              Search
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}

export default DateForm;
