import { Modal } from "@material-ui/core";
import React, { useState } from "react";
import { useStyles } from "./style";

export default function CustomModal({
  children,
  open,
  handleClose,
  width,
  height,
}) {
  const classes = useStyles();
  function rand() {
    return Math.round(Math.random() * 20) - 10;
  }
  function getModalStyle(height, width) {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
      top: `50%`,
      left: `50%`,
      width: width || "800px",
      height: "auto",
      maxHeight: height || "80vh",
      overflow: "scroll",
      transform: "translate(-50%, -50%)",
    };
  }
  const [modalStyle] = useState(getModalStyle(height, width));

  return (
    <Modal
      open={open}
      // maxWidth="lg"
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        {children}
      </div>
    </Modal>
  );
}
