import { authError } from "../components/auth/redux/Auth.action";
import { returnErrors } from "../redux/Error/Error.action";
import Store from "../redux/store";
import { returnSuccess } from "../redux/Success/Success.action";

export function handleError(error) {
  if (error.toJSON().message === "Network Error") {
    alert("no internet connection");
    // dispatch({type: RELOAD});
  } else if (
    error?.response?.data?.error === "Unauthorized" ||
    error?.response?.status === 401
  ) {
    Store.dispatch(returnErrors({ message: "Unauthorized", code: "401" }));
    Store.dispatch(authError());
    window.location.replace("/");
  }
  if (error?.response?.data?.error === "The refresh token is invalid.") {
    Store.dispatch(
      returnErrors(
        error.response?.data || { message: "Error Occurred", code: "400" }
      )
    );
    Store.dispatch(authError());
    window.location.replace("/");
  } else {
    Store.dispatch(
      returnErrors(
        error.response?.data?.apierror?.debugMessage || {
          message: "Error Occurred",
          code: "400",
        }
      )
    );
    throw error;
  }
}

export function handleResponse(response) {
  console.log(response, "res");
  if (response.status === 200) {
    console.log("runnnnnnnnnnn");
    if (response?.data) {
      Store.dispatch(returnSuccess("SuccessFully Completed"));
    } else if (response?.data?.message) {
      Store.dispatch(returnSuccess(response?.data?.message));
    }
  }
  return response;
}
