import { Button, CircularProgress } from "@material-ui/core";
import React, { useState } from "react";

function LoadingButton({
  variant,
  color,
  fullWidth,
  size,
  type,
  loading,
  children,
}) {
  console.log(children, "childr3en");
  return (
    <Button
      variant={variant}
      color={color}
      fullWidth={fullWidth}
      size={size}
      type={type}
      disabled={loading}
    >
      {loading ? <CircularProgress size={14} /> : children}
    </Button>
  );
}

LoadingButton.defaultProps = {
  size: "large",
  color: "primary",
  variant: "contained",
  loadingL: false,
};

export default LoadingButton;
