import React, { Component } from "react";
import classNames from "classnames";
import { Box, Paper } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { useStyles } from "./styles";
import { useState } from "react";
import { useEffect } from "react";

const DashboardBox = (props) => {
  const { title, amount, previousAmt, icons } = props;
  const classes = useStyles();
  const [progressState, setProgressState] = useState();
  const [percentage, setPercentage] = useState();

  const rootClassName = classNames(classes.root);

  useEffect(() => {
    const percentage = ((amount - previousAmt) / ((previousAmt + amount) / 2)) * 100;
    setPercentage(percentage.toFixed(0));
    if (Math.sign(percentage) === 1) {
      setProgressState(true);
    } else {
      setProgressState(false);
    }
    console.log(percentage);
  }, [amount, previousAmt]);

  return (
    <Paper className={rootClassName}>
      <div className={classes.content}>
        <div className={classes.details}>
          <Typography className={classes.title} variant="body2">
            {title}
          </Typography>
          <Typography className={classes.value} variant="h3">
            {amount ? amount : 0}
          </Typography>
        </div>
        <div className={classes.iconWrapper}>{icons}</div>
      </div>
      <div className={classes.footer}>
        {progressState ? (
          <Typography className={classes.differenceUp} variant="body2">
            <ArrowUpwardIcon />
            {!isNaN(percentage) ? percentage : 0}%
          </Typography>
        ) : (
          <Typography className={classes.differenceDown} variant="body2">
            <ArrowDownwardIcon />
            {!isNaN(percentage) ? percentage : 0}%
          </Typography>
        )}
        <Typography className={classes.caption} variant="caption">
          Since last 30 days
        </Typography>
      </div>
    </Paper>
  );
};

export default DashboardBox;
