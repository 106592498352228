import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import DateForm from "./DateForm";
import AccountForm from "./AccountForm";
import VoucherForm from "./VoucherForm";
import ViewJournal from "./ViewJournal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    flexGrow: 1,
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.primary.main,
    },
    "& .MuiTabs-root": {
      backgroundColor: theme.palette.background.main,
    },
  },
}));

function ReportContainer() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [filterData, setFilterData] = useState();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root}>
      <AppBar position="static" color="none" elevation={0}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Date" {...a11yProps(0)} />
          <Tab label="Voucher No." {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <DateForm setFilterData={setFilterData} />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <VoucherForm setFilterData={setFilterData} />
      </TabPanel>
      <ViewJournal filterData={filterData} />
    </div>
  );
}

export default ReportContainer;
