import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { getCustomers, getStaffs, getVendors } from "../../api/MemberApi";
import CreateUser from "./CreateUser";
import ViewUser from "./ViewUser";
import { getUsers } from "../../api/Authapi";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    flexGrow: 1,
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.primary.main,
    },
    "& .MuiTabs-root": {
      backgroundColor: theme.palette.background.main,
    },
  },
}));

function MemberContainer({ account }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [customers, setCustomer] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [staff, setStaff] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleLoading = (status) => {
    if (status) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers().then((res) => setCustomer(res.data));
  }, []);
  return (
    <>
      <CreateUser handleLoading={handleLoading} setCustomer={setCustomer} />

      <div className={classes.root}>
        {loading ? <h2>Loading ...</h2> : <ViewUser customers={customers} />}
      </div>
    </>
  );
}

export default MemberContainer;
