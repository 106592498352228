import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Grid } from "@material-ui/core";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useRef } from "react";
import { useStyles } from "./styles";

const columns1 = [
  { id: "sn", label: "S.No.", minWidth: 10 },
  { id: "drName", label: "Particular", minWidth: 170 },
  {
    id: "amount",
    label: "Dr. Amount",
    minWidth: 50,
    align: "right",
  },
  { id: "crName", label: "Particular", minWidth: 170 },
  {
    id: "amount",
    label: "Cr. Amount",
    minWidth: 50,
    align: "right",
  },
];

function ViewCash({ summary, activeDate }) {
  const classes = useStyles();
  const [drRows, setDrRows] = useState([]);
  const [crRows, setCrRows] = useState([]);
  const [newCrRow, setNewCrRow] = useState([]);
  const [newDrRow, setNewDrRow] = useState([]);
  const doc = new jsPDF();
  const name = localStorage.getItem("name");
  const address = localStorage.getItem("address");
  const tableRef = useRef(null);

  const handlePrint = () => {
    doc.autoTable({
      html: "#first-table",

      startY: 28,

      margin: { top: 28 },
      styles: { overflow: "linebreak" },
      bodyStyles: { valign: "top" },
      columnStyles: { email: { columnWidth: "wrap" } },
      headStyles: {
        overflow: "linebreak",
        halign: "center",
      },
      theme: "striped",
      showHead: "everyPage",

      didDrawPage: function (data) {
        // Header
        doc.setFontSize(18);
        doc.setTextColor(40);
        doc.text(name, 100, 10, { align: "center" });
        doc.setFontSize(12);
        doc.text(address, 100, 16, { align: "center" });
        doc.setFontSize(15);
        doc.text(`Day Close Summary - Date: ${activeDate}`, 100, 22, {
          align: "center",
        });

        // Footer
        var str = "Page " + doc.internal.getNumberOfPages();

        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
    });
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  };

  useEffect(() => {
    setDrRows(summary?.filter((item) => item.drAmt));
    setCrRows(summary?.filter((item) => item.crAmt));
  }, [summary]);

  useEffect(() => {
    if (crRows?.length > drRows?.length) {
      const blankDr = [...Array(crRows?.length - drRows?.length).keys()].map(
        (index) => index
      );
      setNewDrRow(blankDr);
    }
    if (crRows?.length < drRows?.length) {
      const blankCr = [...Array(drRows?.length - crRows?.length).keys()].map(
        (index) => index
      );
      setNewCrRow(blankCr);
    }
    setNewDrRow((prev) => [...prev, ...drRows]);
    setNewCrRow((prev) => [...prev, ...crRows]);
  }, [crRows, drRows]);

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <div className={classes.headerWrapper}>
          <div className={classes.tableHeader}>
            Day Close Summary of {activeDate}
          </div>
          <button onClick={handlePrint} style={{ marginRight: "130px" }}>
            Print
          </button>
          <DownloadTableExcel
            filename={"Day Close Summary Of " + activeDate}
            sheet="Day Close Summary"
            currentTableRef={tableRef.current}
          >
            <button> Export excel </button>
          </DownloadTableExcel>
        </div>

        <Grid container>
          <Grid item sm={12}>
            <Table
              stickyHeader
              aria-label="sticky table"
              id="first-table"
              ref={tableRef}
            >
              <TableHead>
                <TableRow>
                  {columns1.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(newCrRow.length > 0 || newDrRow.length > 0) &&
                  [...Array(newCrRow?.length).keys()].map((index) => (
                    <TableRow
                      hover
                      width={100}
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                    >
                      <TableCell> {index + 1}</TableCell>
                      <TableCell> {newDrRow[index]?.name}</TableCell>
                      <TableCell style={{ textAlign: "right" }}> {newDrRow[index]?.drAmt}</TableCell>
                      <TableCell> {newCrRow[index]?.name}</TableCell>
                      <TableCell style={{ textAlign: "right" }}> {newCrRow[index]?.crAmt}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </TableContainer>
    </Paper>
  );
}

export default ViewCash;
