import React, { useRef, useState } from "react";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import InsideHeader from "../../layout/InsideHeader";
import CreateAccount from "./CreateAccount";
import ViewAccount from "./ViewAccount";
import { useEffect } from "react";
import { fetchAllAccount } from "../../api/AccountApi";
import { motion } from "framer-motion";

function Accounts() {
  const [account, setAccount] = useState([]);
  const [initialAccount, setInitialAccount] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentAccount, setCurrentAccount] = useState();

  const handleSearch = (e) => {
    const filtered = initialAccount?.filter((item) =>
      item?.name?.toLowerCase()?.includes(e.target.value?.toLowerCase())
    );
    console.log(filtered, "filtered");
    setAccount(filtered);
  };

  useEffect(() => {
    fetchAllAccount().then((res) => {
      setAccount(res.data);
      setInitialAccount(res.data);
      console.log(res, "response");
    });
  }, []);
  return (
    <div>
      <InsideHeader title="Accounts">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link color="inherit" href="/">
            Setup
          </Link>

          <Typography color="textPrimary">Accounts</Typography>
        </Breadcrumbs>{" "}
      </InsideHeader>
      <motion.div
        initial={{ opacity: 0, x: "-55%", scale: 0 }}
        animate={{
          scale: 1,
          opacity: 1,
          x: 0,
        }}
        transition={{ duration: 0.5 }}
      >
        <CreateAccount
          account={account}
          setLoading={setLoading}
          loading={loading}
          setAccount={setAccount}
          currentAccount={currentAccount}
          setCurrentAccount={setCurrentAccount}
        />
      </motion.div>
      <Box marginTop="45px" display="flex">
        <TextField
          variant="outlined"
          size="small"
          label="Search Accounts"
          onChange={handleSearch}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
      <motion.div
        initial={{ opacity: 0, x: "-55%", scale: 0 }}
        animate={{
          scale: 1,
          opacity: 1,
          x: 0,
        }}
        transition={{ duration: 0.5 }}
      >
        <ViewAccount
          setCurrentAccount={setCurrentAccount}
          loading={loading}
          account={account}
          setAccount={setAccount}
          setLoading={setLoading}
        />
      </motion.div>
    </div>
  );
}

export default Accounts;
