import { axiosInstance } from "../utils/AxiosInstance";
import { handleError } from "./apiResponse";

export const addSchema = (data) => {
  return axiosInstance()
    .post(`/transactions/account/fee-scheme`, data)
    .catch(handleError);
};

export const getSchema = () => {
  return axiosInstance()
    .get(`/transactions/account/fee-scheme/all`)
    .catch(handleError);
};
