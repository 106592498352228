import { axiosInstance } from "../utils/AxiosInstance";
import { handleError, handleResponse } from "./apiResponse";

// all Journal Voucher
export function addJournalVoucher(data) {
  return axiosInstance()
    .post("/transactions/account/journal-vouchers/save", data)
    .then(handleResponse)
    .catch(handleError);
}

export function editJournalVoucher(data) {
  return axiosInstance()
    .put("/transactions/account/journal-vouchers/edit", data)
    .then(handleResponse)
    .catch(handleError);
}

export function fetchUnapprovedJournalVoucher(pageable) {
  return axiosInstance()
    .get(`/transactions/account/journal-vouchers/unapproved`, {
      params: {
        pageable: pageable,
      },
    })
    .catch(handleError);
}

export function fetchUnapprovedJournalVoucherByDate(
  date,
  pageable,
  isBackDate
) {
  return axiosInstance()
    .get(`/transactions/account/journal-vouchers/unapproved-date`, {
      params: {
        date: date,
        pageable: pageable,
        isBackDate: isBackDate,
      },
    })
    .catch(handleError);
}

export function fetchApprovedJournalVoucherByDates(fromDate, toDate, pageable) {
  return axiosInstance()
    .get(`/transactions/account/journal-vouchers/approved-dates`, {
      params: {
        fromDate: fromDate,
        toDate: toDate,
        ...pageable,
      },
    })
    .catch(handleError);
}

export function approveJournalVoucher(jvId, isApprove) {
  return axiosInstance()
    .post(
      `/transactions/account/journal-vouchers/posting?jvId=${jvId}&isApprove=${isApprove}`
    )
    .then(handleResponse)
    .catch(handleError);
}

export function fetchUnapprovedJournalVoucherByVoucherNo(jvType, voucherNo) {
  return axiosInstance()
    .get(
      `/transactions/account/journal-vouchers/jv-no?jv-type=${jvType}&jv-no=${voucherNo}`
    )
    .catch(handleError);
}
