import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { Grid, TableFooter } from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useRef } from "react";

const columns = [
  { id: "sn", label: "S.No.", minWidth: 60 },
  { id: "name", label: "Name", minWidth: 100 },
  { id: "code", label: "Code", minWidth: 80 },
  { id: "accType", label: "Acc Type", minWidth: 60 },
  { id: "alias", label: "Alias", minWidth: 200 },
  { id: "drAmt", label: "Debit", minWidth: 80 },
  { id: "crAmt", label: "Credit", minWidth: 80 },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    "& .MuiTableCell-stickyHeader": {
      background: theme.palette.background.dark,
      //   color: theme.palette.primary.dark,
    },
    "& .MuiTableRow-footer": {
      background: theme.palette.background.dark,
      "& td": {
        fontSize: "16px",
      },
    },

    "& .MuiTableBody-root": {
      "& tr:nth-of-type(odd)": {
        background: theme.palette.background.main,
      },
    },
  },
  container: {
    maxHeight: 550,
    borderRadius: "5px",
  },
  lastRow: {
    background: "#c0ddf9",
  },
  headerWrapper: {
    position: "relative",
    "& button": {
      position: "absolute",
      right: "10px",
      top: "10px",
      padding: "10px 20px",
      borderRadius: "4px",
      border: "none",
      background: "#67B3FA",
      color: "white",
      cursor: "pointer",
    },
  },
  tableHeader: {
    padding: "15px 0px",
    textAlign: "center",
    fontSize: "20px",
    // background: theme.palette.background.dark,
  },
  headerTitle: {
    fontSize: "16px",
    padding: "12px 0",
    width: "100%",
    textAlign: "center",
    background: theme.palette.background.main,
    color: theme.palette.primary.dark,
  },
}));

function ViewLedger({ ledgers, dates, accountName }) {
  console.log(ledgers, "ledgers");
  const classes = useStyles();

  const doc = new jsPDF();
  const name = localStorage.getItem("name");
  const address = localStorage.getItem("address");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const tableRef = useRef(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handlePrint = () => {
    doc.autoTable({
      html: "#first-table",

      startY: 32,

      margin: { top: 32 },
      styles: { overflow: "linebreak" },
      bodyStyles: { valign: "top" },
      columnStyles: { email: { columnWidth: "wrap" } },
      headStyles: {
        overflow: "linebreak",
        halign: "center",
      },
      theme: "striped",
      showHead: "everyPage",

      didDrawPage: function (data) {
        // Header
        doc.setFontSize(18);
        doc.setTextColor(40);
        doc.text(name, 100, 10, { align: "center" });
        doc.setFontSize(12);
        doc.text(address, 100, 16, { align: "center" });
        doc.setFontSize(15);
        doc.text(`${accountName}`, 100, 22, { align: "center" });
        doc.text(
          `Ledger Report - From: ${dates?.from} To: ${dates.to}`,
          100,
          28,
          {
            align: "center",
          }
        );

        // Footer
        var str = "Page " + doc.internal.getNumberOfPages();

        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
    });
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <div className={classes.headerWrapper}>
          <div className={classes.tableHeader}>
            Parent Ledger from {dates?.from} to {dates?.to}
          </div>
          <button onClick={handlePrint} style={{ marginRight: "130px" }}>
            Print
          </button>
          <DownloadTableExcel
            filename={"Parent Ledger - From " + dates?.from + " To " + dates?.to}
            sheet="Parent Ledger"
            currentTableRef={tableRef.current}
          >
            <button> Export excel </button>
          </DownloadTableExcel>
        </div>

        <Grid container>
          <Grid item sm={12}>
            <Table
              stickyHeader
              aria-label="sticky table"
              id="first-table"
              ref={tableRef}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {[...Array(ledgers?.length).keys()]
                  // ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((index) => (
                    <TableRow
                      hover
                      width={100}
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell> {ledgers[index]?.name}</TableCell>
                      <TableCell> {ledgers[index]?.code}</TableCell>
                      <TableCell> {ledgers[index]?.accType}</TableCell>
                      <TableCell> {ledgers[index]?.alias}</TableCell>
                      <TableCell>
                        {" "}
                        {ledgers[index]?.drCrType == "DR" &&
                          ledgers[index]?.amt}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {ledgers[index]?.drCrType == "CR" &&
                          ledgers[index]?.amt}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={[...Array(ledgers?.length).keys()]?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
}

export default ViewLedger;
