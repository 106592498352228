import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { CustomNepaliDatePicker } from "../common/nepalidatepicker/NepaliDatePicker";
import { getCurrentDate } from "../../utils/TimestamptoDate";
import { useForm } from "react-hook-form";
import { CustomInput } from "../common/customInput/CustomInput";
import { dayCloseApi } from "../../api/DashboardApi";
import { useDispatch } from "react-redux";
import { fetchWorkingDay } from "../auth/redux/Auth.action";
import CustomModal from "../common/customModal/CustomModal";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
}));
function CloseSession({ active }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const defaultValues = {
    date: active,
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues });

  const onSubmit = (values) => {
    setOpen(true);
  };
  const finalSubmit = () => {
    dayCloseApi(true).then((res) => {
      handleClose();
      dispatch(fetchWorkingDay());
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box marginTop={6}>
        <h3> Close session of the day</h3>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
        <Grid container spacing={4}>
          <Grid item sm={3}>
            <CustomInput
              control={control}
              errors={errors}
              name="date"
              disabled
            />
          </Grid>

          <Grid item sm={3}>
            <Box marginLeft={5} marginTop={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                type="submit"
              >
                Close Session
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
      <CustomModal open={open} handleClose={handleClose} width="350px">
        <h3
          style={{
            marginBottom: 0,
          }}
        >
          Do you want to Close Session?
        </h3>
        <Box marginBottom={3}>
          <Typography variant="body2">
            After Closing session, you can't edit any data for the day.
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Button variant="contained" color="primary" onClick={finalSubmit}>
            Yes
          </Button>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            No
          </Button>
        </Box>
      </CustomModal>
    </>
  );
}

export default CloseSession;
