import Account from "../pages/Dashboard/Account";
import Dashboard from "../pages/Dashboard/Dashboard";
import JournalVoucherApprove from "../pages/Dashboard/JournalVoucherApprove";
import JournalVoucherEntry from "../pages/Dashboard/JournalVoucherEntry";
import JournalVoucherReport from "../pages/Dashboard/JournalVoucherReport";
import Ledger from "../pages/Dashboard/reports/Ledger";
import BalanceSheet from "../pages/Dashboard/reports/BalanceSheet";
import CashInOut from "../pages/Dashboard/reports/CashInOut";
import ProfitLoss from "../pages/Dashboard/reports/ProfitLoss";
import TrialBalance from "../pages/Dashboard/reports/TrialBalance";
import SignIn from "../pages/Home/SignIn";
import Receipt from "../pages/Dashboard/Receipt";
import Members from "../pages/Dashboard/Members";
import Payment from "../pages/Dashboard/Payment";
import Tax from "../pages/Dashboard/Tax";
import FeeSchema from "../pages/Dashboard/FeeSchema";
import Session from "../pages/Dashboard/Session";
import LedgerParent from "../pages/Dashboard/reports/LedgerParent";
import JournalVoucherEntryBackDate from "../pages/Dashboard/JournalVoucherEntryBackDate";
import User from "../pages/Dashboard/User";

const routes = {
  home: [
    {
      path: "/signIn",
      component: SignIn,
    },

    // {
    //   path: "/blog",
    //   component: Blog,
    //   routes: [
    //     {
    //       path: "/blog/new",
    //       component: New,
    //     },
    //     {
    //       path: "/blog/old",
    //       component: Old,
    //     },
    //   ],
    // },
  ],
  dashboard: [
    {
      path: "/",
      component: Dashboard,
      exact: true,
    },
    {
      path: "/members",
      component: Members,
      exact: true,
    },
    {
      path: "/users",
      component: User,
      exact: true,
    },
    {
      path: "/journal_vouche_entry",
      component: JournalVoucherEntry,
      exact: true,
    },
    {
      path: "/journal_voucher_entry_back_date",
      component: JournalVoucherEntryBackDate,
      exact: true,
    },
    {
      path: "/journal_voucher_approve",
      component: JournalVoucherApprove,
      exact: true,
    },
    {
      path: "/journal_voucher_report",
      component: JournalVoucherReport,
      exact: true,
    },

    {
      path: "/accounts",
      component: Account,
      exact: true,
    },
    {
      path: "/receipt",
      component: Receipt,
      exact: true,
    },
    {
      path: "/payment",
      component: Payment,
      exact: true,
    },
    {
      path: "/tax",
      component: Tax,
      exact: true,
    },
    {
      path: "/schema",
      component: FeeSchema,
      exact: true,
    },
    {
      path: "/reports/trail_balance",
      component: TrialBalance,
      exact: true,
    },
    {
      path: "/reports/pl",
      component: ProfitLoss,
      exact: true,
    },
    {
      path: "/reports/balance_sheet",
      component: BalanceSheet,
      exact: true,
    },
    {
      path: "/reports/cash_in_out",
      component: CashInOut,
      exact: true,
    },
    {
      path: "/reports/ledger",
      component: Ledger,
      exact: true,
    },
    {
      path: "/reports/ledger-parent",
      component: LedgerParent,
      exact: true,
    },
    {
      path: "/session",
      component: Session,
      exact: true,
    },
  ],
};

export default routes;
