import { Box, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  fetchAllAccount,
  findAccountType,
  findMappingPurpose,
} from "../../api/AccountApi";
import { addTax, getTransactions } from "../../api/TaxApi";
import { CustomInput } from "../common/customInput/CustomInput";
import { CustomSelect } from "../common/customSelect/CustomSelect";
import { SearchableSelect } from "../common/searchableSelect/SearchableSelect";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
  },
}));

function CreateTax({ account, setLoading, setTax }) {
  const classes = useStyles();
  const defaultValues = {
    name: "",
    accHeadId: "",
    rate: null,
  };
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues });

  const onSubmit = (values) => {
    console.log(values);
    setLoading(true);
    addTax(values)
      .then((res) => {
        setLoading(false);
        reset();
        getTransactions().then((res) => {
          setTax(res.data);
        });
      })
      .catch((err) => setLoading(false));
  };

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item sm={3}>
            <CustomInput
              label="Tax Name"
              control={control}
              errors={errors}
              name="name"
            />
          </Grid>
          <Grid item sm={3}>
            <SearchableSelect
              options={account?.map((item) => ({
                label: `${item.name}(${item.code})`,
                value: item.id,
              }))}
              label="Account Name"
              control={control}
              errors={errors}
              name="accHeadId"
              watch={watch}
            />
          </Grid>
          <Grid item sm={3}>
            <CustomInput
              label="Tax Rate"
              control={control}
              name="rate"
              errors={errors}
            />
          </Grid>
          <Grid item sm={3}>
            <CustomInput
              label="Alias"
              control={control}
              errors={errors}
              name="alias"
            />
          </Grid>

          {/* <Grid item sm={3}>
            <CustomSelect
              label="Account Type"
              control={control}
              errors={errors}
              name="type"
              options={accType?.map((item) => ({
                label: item.cvLbl,
                value: item.cvCode,
              }))}
            />
          </Grid> */}
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid item sm={2}>
            <Box marginTop={4} marginLeft={5}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                type="submit"
              >
                Add
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default CreateTax;
