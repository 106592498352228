import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  fetchAllAccount,
  fetchLeafAccount,
  findAccountType,
} from "../../api/AccountApi";
import { fetchLedger } from "../../api/ReportApi";
import { getCurrentDate } from "../../utils/TimestamptoDate";
import { CustomInput } from "../common/customInput/CustomInput";
import { CustomSelect } from "../common/customSelect/CustomSelect";
import { CustomNepaliDatePicker } from "../common/nepalidatepicker/NepaliDatePicker";
import { SearchableSelect } from "../common/searchableSelect/SearchableSelect";
import { SearchableSelectKeys } from "../common/searchableSelect/SearchableSelectKeys";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
  },
  lastRow: {
    background: "#c0ddf9",
  },
}));

function FilterForm({ setLedgers, setAccountName, setDates }) {
  const classes = useStyles();
  const defaultValues = {
    fromDate: getCurrentDate(),
    toDate: getCurrentDate(),
    accHeadId: "",
    accountType: "All",
  };
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({ defaultValues });
  const [account, setAccount] = useState([]);
  const [accType, setAccType] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState([]);

  useEffect(() => {
    fetchLeafAccount().then((res) => setAccount(res.data));
    findAccountType().then((res) =>
      setAccType([{ cvLbl: "All" }, ...res.data])
    );
  }, []);

  const accountType = watch("accountType");
  const accHeadId = watch("accHeadId");

  const onSubmit = (values) => {
    if (accHeadId) {
      setDates({ from: values?.fromDate, to: values?.toDate });
      const checkName = selectedAccount?.filter((item) => item.id == accHeadId);
      console.log(checkName);
      checkName && setAccountName(checkName[0]["name"]);
      fetchLedger(values).then((res) => setLedgers(res.data));
    }
  };

  useEffect(() => {
    if (accountType === "All") {
      setSelectedAccount(account);
    } else {
      setSelectedAccount(account?.filter((item) => item?.type === accountType));
    }
  }, [accountType, account]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container spacing={4}>
        <Grid item sm={3}>
          <CustomNepaliDatePicker
            label="From"
            control={control}
            errors={errors}
            name="fromDate"
          />
        </Grid>
        <Grid item sm={3}>
          <CustomNepaliDatePicker
            label="To"
            control={control}
            errors={errors}
            name="toDate"
          />
        </Grid>
        <Grid item sm={3}>
          <CustomSelect
            label="Account Type"
            control={control}
            errors={errors}
            name="accountType"
            options={accType?.map((item) => ({
              label: item.cvLbl,
              value: item.cvLbl,
            }))}
          />
        </Grid>
        <Grid item sm={3}>
          <SearchableSelectKeys
            options={selectedAccount?.map((item) => ({
              label: `${item.name}(${item.code})`,
              value: item.id,
            }))}
            label="Account Name"
            keys={accountType}
            control={control}
            errors={errors}
            name="accHeadId"
          />
        </Grid>
        <Grid item sm={12}>
          <Box marginLeft={5} display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
            >
              Search
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}

export default FilterForm;
