import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { CustomNepaliDatePicker } from "../common/nepalidatepicker/NepaliDatePicker";
import { formatToUnix, getCurrentDate } from "../../utils/TimestamptoDate";
import { useForm } from "react-hook-form";
import { dayStartApi } from "../../api/DashboardApi";
import { fetchWorkingDay } from "../auth/redux/Auth.action";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
}));
function StartSession() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const defaultValues = {
    date: getCurrentDate(),
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues });

  const onSubmit = (values) => {
    console.log(values);

    dayStartApi(formatToUnix(values?.date)).then((res) => {
      dispatch(fetchWorkingDay());
    });
  };

  return (
    <>
      <Box marginTop={6}>
        <h3> Start session of the day</h3>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
        <Grid container spacing={4}>
          <Grid item sm={3}>
            <CustomNepaliDatePicker
              control={control}
              errors={errors}
              name="date"
            />
          </Grid>

          <Grid item sm={3}>
            <Box marginLeft={5}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                type="submit"
              >
                Start Session
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default StartSession;
