import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    "& .MuiTableCell-stickyHeader": {
      background: theme.palette.background.dark,
      //   color: theme.palette.primary.dark,
    },
    "& .MuiTableRow-footer": {
      background: theme.palette.background.dark,
      "& td": {
        fontSize: "16px",
      },
    },

    "& .MuiTableBody-root": {
      "& tr:nth-of-type(odd)": {
        background: theme.palette.background.main,
      },
    },
  },
  container: {
    maxHeight: 550,
    borderRadius: "5px",
  },
  lastRow: {
    background: "#c0ddf9 !important",
  },
  tableHeader: {
    padding: "15px 0px",
    textAlign: "center",
    fontSize: "20px",
    background: theme.palette.background.dark,
  },
  headerWrapper: {
    position: "relative",
    "& button": {
      position: "absolute",
      right: "10px",
      top: "10px",
      padding: "10px 20px",
      borderRadius: "4px",
      border: "none",
      background: "#67B3FA",
      color: "white",
      cursor: "pointer",
    },
  },
  headerTitle: {
    fontSize: "16px",
    padding: "12px 0",
    width: "100%",
    textAlign: "center",
    background: theme.palette.background.main,
    color: theme.palette.primary.dark,
  },
  tableRow: {
    whiteSpace: "pre"
  },
  boldRow: {
    fontWeight: "bold"
  }
}));
