import { Box, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  addAccountHead,
  fetchAllAccount,
  fetchLeafAccountByCode,
  findAccountType,
  findMappingPurpose,
} from "../../api/AccountApi";
import { addSchema, getSchema } from "../../api/FeeSchema";
import CustomCheckbox from "../common/customCheckbox/CustomCheckbox";
import { CustomInput } from "../common/customInput/CustomInput";
import { CustomSelect } from "../common/customSelect/CustomSelect";
import LoadingButton from "../common/loadingButton/LoadingButton";
import { SearchableSelect } from "../common/searchableSelect/SearchableSelect";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(0),
  },
}));

function CreateFeeSchema({ tax, setLoading, loading, setSchema }) {
  const classes = useStyles();
  const [account, setAccount] = useState([]);
  const defaultValues = {
    name: "",
    accHeadId: "",
    rate: "",
    taxId: "",
    payment: true,
  };
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues });

  const onSubmit = (values) => {
    console.log(values);
    setLoading(true);
    addSchema(values)
      .then((res) => {
        reset();
        getSchema().then((res) => setSchema(res.data));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const payment = watch("payment");
  useEffect(() => {
    setAccount([]);
    if (payment) {
      fetchLeafAccountByCode(4).then((res) => setAccount(res.data));
    } else {
      fetchLeafAccountByCode(3).then((res) => setAccount(res.data));
    }
  }, [payment]);

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item sm={3}>
            <CustomCheckbox
              label="Payment"
              control={control}
              errors={errors}
              name="payment"
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item sm={3}>
            <CustomInput
              label="Schema Name"
              control={control}
              errors={errors}
              name="name"
            />
          </Grid>
          <Grid item sm={3}>
            <SearchableSelect
              options={account?.map((item) => ({
                label: `${item.name}(${item.code})`,
                value: item.id,
              }))}
              label="Account Name"
              control={control}
              errors={errors}
              watch={watch}
              name="accHeadId"
            />
          </Grid>
          <Grid item sm={3}>
            <SearchableSelect
              options={tax?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              label="Tax"
              control={control}
              watch={watch}
              errors={errors}
              name="taxId"
            />
          </Grid>
          <Grid item sm={3}>
            <CustomInput
              label="Rate"
              type="number"
              control={control}
              name="rate"
              errors={errors}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" spacing={4}>
          <Grid item sm={2}>
            <Box marginTop={2} marginLeft={5}>
              <LoadingButton
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                loading={loading}
              >
                Add
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default CreateFeeSchema;
