import React from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Login from "../../components/auth/login/Login";

function Copyright() {
  return (
    <Box
      variant="body2"
      color="textSecondary"
      align="center"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {"Copyright ©  "}
      <a color="inherit" href="https://allstar.com.np">
        <img
          src="/allstar_logo.png"
          height="40px"
          width="80px"
          style={{ margin: "0px 10px" }}
          alt=""
        />
      </a>{" "}
      {new Date().getFullYear()}
      {"."}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "calc(50vh - 60%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "0px 2px 6px #c5c5c5",
    borderRadius: "6px",
    padding: "45px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  title: {
    color: "#2b92d7",
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "18px",
  },
}));

export default function SignIn() {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" className={classes.title}>
          Accounting System
        </Typography>
        <Login />
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
