import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useForm } from "react-hook-form";
import { CustomInput } from "../common/customInput/CustomInput";
import { SearchableSelect } from "../common/searchableSelect/SearchableSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
}));

function AccountForm() {
  const classes = useStyles();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (values) => {
    console.log(values);
  };
  const top100Films = [
    { title: "The Shawshank Redemption", year: 1994 },
    { title: "The Godfather", year: 1972 },
    { title: "The Godfather: Part II", year: 1974 },
    { title: "Full Metal Jacket", year: 1987 },
    { title: "Dangal", year: 2016 },
    { title: "The Sting", year: 1973 },
    { title: "2001: A Space Odyssey", year: 1968 },
    { title: "Singin' in the Rain", year: 1952 },
    { title: "Toy Story", year: 1995 },
    { title: "Bicycle Thieves", year: 1948 },
    { title: "The Kid", year: 1921 },
    { title: "Inglourious Basterds", year: 2009 },
    { title: "Snatch", year: 2000 },
    { title: "3 Idiots", year: 2009 },
    { title: "Monty Python and the Holy Grail", year: 1975 },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container spacing={4}>
        <Grid item sm={3}>
          <SearchableSelect
            options={top100Films}
            label="Account Name"
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid item sm={3}>
          <CustomInput
            label="From"
            type="date"
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid item sm={3}>
          <CustomInput
            label="To"
            type="date"
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid item sm={2}>
          <Box marginLeft={5} marginTop={2}>
            <Button variant="contained" color="primary" fullWidth size="large">
              Search
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}

export default AccountForm;
