import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useForm } from "react-hook-form";
import { fetchTrialBalance } from "../../api/ReportApi";
import { getCurrentDate } from "../../utils/TimestamptoDate";
import { CustomInput } from "../common/customInput/CustomInput";
import { CustomSelect } from "../common/customSelect/CustomSelect";
import { CustomNepaliDatePicker } from "../common/nepalidatepicker/NepaliDatePicker";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
  },
}));

function FilterForm({ setTrialBalance, setDates }) {
  const classes = useStyles();
  const defaultValues = {
    fromDate: getCurrentDate(),
    toDate: getCurrentDate(),
    level: 1,
    type: 1,
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues });

  const onSubmit = (values) => {
    console.log(values);
    setDates({ from: values?.fromDate, to: values?.toDate });
    fetchTrialBalance(values).then((res) => setTrialBalance(res.data));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container spacing={4}>
        <Grid item sm={3}>
          <CustomNepaliDatePicker
            label="From"
            control={control}
            errors={errors}
            name="fromDate"
          />
        </Grid>
        <Grid item sm={3}>
          <CustomNepaliDatePicker
            label="To"
            control={control}
            errors={errors}
            name="toDate"
          />
        </Grid>
        <Grid item sm={2}>
          <CustomSelect
            label="Level"
            control={control}
            errors={errors}
            name="level"
            options={[
              { label: "Quarterly", value: 1 },
              { label: "Yearly", value: 2 },
            ]}
          />
        </Grid>
        <Grid item sm={2}>
          <CustomSelect
            label="Type"
            control={control}
            errors={errors}
            name="type"
            options={[
              { label: "Summary", value: 1 },
              { label: "Detail", value: 2 },
            ]}
          />
        </Grid>
        <Grid item sm={2}>
          <Box marginLeft={5} marginTop={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              type="submit"
            >
              Search
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}

export default FilterForm;
