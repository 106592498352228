import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import { deleteAccountHead, fetchAllAccount } from "../../api/AccountApi";
import { Alert } from "@material-ui/lab";
import { Edit } from "@material-ui/icons";

const columns = [
  { id: "name", label: "Name", minWidth: 200 },

  { id: "code", label: "Code ", minWidth: 60 },
  {
    id: "alias",
    label: "Alias",
    minWidth: 140,
    align: "center",
  },
  {
    id: "drCrType",
    label: "Debit/Credit",
    minWidth: 100,
    align: "center",
  },
  {
    id: "forBranch",
    label: "For Branch",
    minWidth: 120,
    align: "center",
  },

  {
    id: "parent",
    label: "Parent",
    minWidth: 60,
    align: "center",
  },
  {
    id: "type",
    label: "Type",
    minWidth: 60,
    align: "center",
  },
  {
    id: "action",
    label: "Action",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
    "& .MuiTableCell-stickyHeader": {
      background: theme.palette.background.dark,
      //   color: theme.palette.primary.dark,
    },
    "& .MuiTableBody-root": {
      "& tr:nth-of-type(odd)": {
        background: theme.palette.background.main,
      },
    },
  },
  delete: {
    color: "#ff6363",
    cursor: "pointer",
    "&:hover": {
      color: "red",
    },
  },
  edit: {
    color: "#639cff",
    cursor: "pointer",
    marginRight: "10px",
    "&:hover": {
      color: "red",
    },
  },
  container: {
    maxHeight: 550,
    borderRadius: "5px",
  },
}));

function ViewAccount({
  account,
  setAccount,
  setLoading,
  loading,
  setCurrentAccount,
}) {
  const classes = useStyles();
  const rows = account;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDelete = (id) => {
    setLoading(true);
    deleteAccountHead(id)
      .then((res) => {
        fetchAllAccount().then((res) => setAccount(res.data));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    setCurrentAccount(id);
  };

  return loading ? (
    <h2>Loading ....</h2>
  ) : (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>S.No.</TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    <TableCell>{index + 1}</TableCell>
                    {columns.map((column, ind) => {
                      const value = row[column.id];
                      return columns?.length !== ind + 1 ? (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : String(value)}
                        </TableCell>
                      ) : (
                        <TableCell align={column.align}>
                          <span>
                            <Edit
                              className={classes.edit}
                              onClick={() => handleEdit(row?.id)}
                            />
                          </span>
                          <span>
                            <DeleteIcon
                              className={classes.delete}
                              onClick={() => handleDelete(row?.id)}
                            />
                          </span>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default ViewAccount;
