import { makeStyles, List, Box } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { PeopleAlt } from "@material-ui/icons";
import DashboardIcon from "@material-ui/icons/Dashboard";
import React from "react";
import { NavLink } from "react-router-dom";
import ListCollapse from "./ListCollapse";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DescriptionIcon from "@material-ui/icons/Description";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import ReceiptIcon from "@material-ui/icons/Receipt";
import PaymentIcon from "@material-ui/icons/Payment";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import SettingsIcon from "@material-ui/icons/Settings";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";

const useStyles = makeStyles((theme) => ({
  listbtn: {
    paddingLeft: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    backgroundColor: "#fff",
  },
  listItemIcon: {
    minWidth: theme.spacing(4.5),
    "& .MuiSvgIcon-root": {
      fontSize: "36px",
    },
  },
  wrapListItems: {
    height: "calc(100vh - 60px)",
    overflowY: "auto",
  },

  button: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "& .MuiTypography-body1": {
      fontSize: "0.8rem",
    },
    "&:hover": {
      color: "#fff !important",
      background: "#61b5f1",
      borderRadius: "3px",
    },
    // "&:hover::before": {
    //   content: '""',
    //   height: "100%",
    //   position: "absolute",
    //   top: "0px",
    //   borderRight: `5px solid #61b5f1`,
    //   left: "-28px",
    //   borderBottomRightRadius: "5px",
    //   borderTopRightRadius: "5px",
    // },
    padding: `${theme.spacing(2.5)}px ${theme.spacing(2)}px`,
    color: "#757575",
    borderBottom: "1px solid #e3e3e3",
    "&:hover $activeIcon": {
      color: "#fff",
    },
  },
  active: {
    background: "#61b5f1",
    color: "#fff !important",
    // "&::before": {
    //   content: '""',
    //   height: "100%",
    //   position: "absolute",
    //   top: "0px",
    //   borderRight: `5px solid #61b5f1`,
    //   left: "-28px",
    //   borderBottomRightRadius: "5px",
    //   borderTopRightRadius: "5px",
    // },
    "&::after": {},
    "& $activeIcon": {
      color: "#fff",
    },
  },

  activeIcon: {},
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const ListItems = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapListItems}>
      <ListItem
        button
        className={classes.button}
        component={NavLink}
        exact
        to={`/`}
        activeClassName={classes.active}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <DashboardIcon className={classes.activeIcon} />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>

      <ListItem
        button
        className={classes.button}
        component={NavLink}
        to={`/journal_vouche_entry`}
        activeClassName={classes.active}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <DescriptionIcon className={classes.activeIcon} />
        </ListItemIcon>
        <ListItemText primary="JV Entry" />
      </ListItem>
      <ListItem
        button
        className={classes.button}
        component={NavLink}
        to={`/journal_voucher_entry_back_date`}
        activeClassName={classes.active}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <DescriptionIcon className={classes.activeIcon} />
        </ListItemIcon>
        <ListItemText primary="JV Back Date" />
      </ListItem>
      <ListItem
        button
        className={classes.button}
        component={NavLink}
        to={`/journal_voucher_approve`}
        activeClassName={classes.active}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <CheckCircleOutlineIcon className={classes.activeIcon} />
        </ListItemIcon>
        <ListItemText primary="JV Approve" />
      </ListItem>

      <ListItem
        button
        className={classes.button}
        component={NavLink}
        exact
        to={`/receipt`}
        activeClassName={classes.active}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <ReceiptIcon className={classes.activeIcon} />
        </ListItemIcon>
        <ListItemText primary="Receipt" />
      </ListItem>
      <ListItem
        button
        className={classes.button}
        component={NavLink}
        exact
        to={`/payment`}
        activeClassName={classes.active}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <PaymentIcon className={classes.activeIcon} />
        </ListItemIcon>
        <ListItemText primary="Payment" />
      </ListItem>

      <ListCollapse label="Reports" icon={<FileCopyIcon />}>
        <List component="div" disablePadding className={classes.listbtn}>
          <ListItem
            button
            className={`${classes.button} ${classes.nested}`}
            component={NavLink}
            to="/reports/trail_balance"
            activeClassName={classes.active}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <FileCopyIcon className={classes.activeIcon} />
            </ListItemIcon>
            <ListItemText primary="Trial Balance" />
          </ListItem>
          <ListItem
            button
            className={`${classes.button} ${classes.nested}`}
            component={NavLink}
            to="/reports/pl"
            activeClassName={classes.active}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <FileCopyIcon className={classes.activeIcon} />
            </ListItemIcon>
            <ListItemText primary="Profit & Loss" />
          </ListItem>

          <ListItem
            button
            className={`${classes.button} ${classes.nested}`}
            component={NavLink}
            to="/reports/balance_sheet"
            activeClassName={classes.active}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <FileCopyIcon className={classes.activeIcon} />
            </ListItemIcon>
            <ListItemText primary="Balance Sheet" />
          </ListItem>

          <ListItem
            button
            className={`${classes.button} ${classes.nested}`}
            component={NavLink}
            to="/reports/cash_in_out"
            activeClassName={classes.active}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <FileCopyIcon className={classes.activeIcon} />
            </ListItemIcon>
            <ListItemText primary="Day Close Summary" />
          </ListItem>
          <ListItem
            button
            className={classes.button}
            component={NavLink}
            to={`/reports/ledger`}
            activeClassName={classes.active}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <AllInboxIcon className={classes.activeIcon} />
            </ListItemIcon>
            <ListItemText primary="Ledger" />
          </ListItem>
          <ListItem
            button
            className={classes.button}
            component={NavLink}
            to={`/reports/ledger-parent`}
            activeClassName={classes.active}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <AllInboxIcon className={classes.activeIcon} />
            </ListItemIcon>
            <ListItemText primary="Ledger Parent" />
          </ListItem>
          <ListItem
            button
            className={classes.button}
            component={NavLink}
            to={`/journal_voucher_report`}
            activeClassName={classes.active}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <FileCopyIcon className={classes.activeIcon} />
            </ListItemIcon>
            <ListItemText primary="JV Report" />
          </ListItem>
        </List>
      </ListCollapse>
      <ListCollapse label="Setup" icon={<SettingsIcon />}>
        <List component="div" disablePadding className={classes.listbtn}>
          <ListItem
            button
            className={classes.button}
            component={NavLink}
            exact
            to={`/users`}
            activeClassName={classes.active}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <PaymentIcon className={classes.activeIcon} />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
          <ListItem
            button
            className={classes.button}
            component={NavLink}
            exact
            to={`/members`}
            activeClassName={classes.active}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <PaymentIcon className={classes.activeIcon} />
            </ListItemIcon>
            <ListItemText primary="Members" />
          </ListItem>
          <ListItem
            button
            className={classes.button}
            component={NavLink}
            to={`/accounts`}
            activeClassName={classes.active}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <AccountBalanceWalletIcon className={classes.activeIcon} />
            </ListItemIcon>
            <ListItemText primary="Accounts" />
          </ListItem>
          <ListItem
            button
            className={classes.button}
            component={NavLink}
            to={`/tax`}
            activeClassName={classes.active}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <AccountBalanceIcon className={classes.activeIcon} />
            </ListItemIcon>
            <ListItemText primary="Tax" />
          </ListItem>
          <ListItem
            button
            className={classes.button}
            component={NavLink}
            to={`/schema`}
            activeClassName={classes.active}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <FormatListBulletedIcon className={classes.activeIcon} />
            </ListItemIcon>
            <ListItemText primary="Schema" />
          </ListItem>
        </List>
      </ListCollapse>
      <ListItem
        button
        className={classes.button}
        component={NavLink}
        to={`/session`}
        activeClassName={classes.active}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <AccessTimeIcon className={classes.activeIcon} />
        </ListItemIcon>
        <ListItemText primary="Session" />
      </ListItem>
    </div>
  );
};

export default ListItems;
