import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useState } from "react";
import CustomModal from "../common/customModal/CustomModal";
import jsPDF from "jspdf";
import "jspdf-autotable";

const columns = [
  { id: "accountName", label: "Account Name", minWidth: 180 },
  { id: "drAmt", label: "Debit Amt", minWidth: 100 },
  { id: "crAmt", label: "Credit Amt", minWidth: 100 },
  { id: "remarks", label: "Remarks", minWidth: 200 },
];
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiTableCell-stickyHeader": {
      background: theme.palette.background.dark,
      //   color: theme.palette.primary.dark,
    },
    "& .MuiTableBody-root": {
      "& tr:nth-of-type(odd)": {
        background: theme.palette.background.main,
      },
    },
  },
  lastRow: {
    background: "#c0ddf9",
  },
  container: {
    maxHeight: 550,
    borderRadius: "5px",
  },
  viewBtn: {
    background: theme.palette.primary.main,
    padding: "8px 12px",
    borderRadius: "4px",
    cursor: "pointer",
    color: "#fff",
  },
  boxWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.background.main,
    padding: theme.spacing(1.5),
    "& h5": {
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(2),
    },
  },
}));
const DetailModal = ({ data, open, handleClose, printDate, jv }) => {
  const classes = useStyles();
  const doc = new jsPDF();
  const name = localStorage.getItem("name");
  const address = localStorage.getItem("address");
  const date = "2022-01-02";

  const handlePrint = () => {
    doc.autoTable({
      html: "#print-detail",

      startY: 32,

      margin: { top: 32 },
      styles: { overflow: "linebreak" },
      bodyStyles: { valign: "top" },
      columnStyles: { email: { columnWidth: "wrap" } },
      headStyles: {
        overflow: "linebreak",
        align: "center",
      },
      theme: "striped",
      showHead: "everyPage",

      didDrawPage: function (data) {
        // Header
        // doc.setFontSize(18);
        // doc.setTextColor(40);
        // doc.text(name, 66, 10);
        // doc.setFontSize(12);
        // doc.text(address, 80, 16);
        // doc.setFontSize(15);
        // doc.text(`JV Number: ${jv} - Date: ${printDate} `, 64, 24);

        doc.setFontSize(18);
        doc.setTextColor(40);
        doc.text(name, 100, 10, { align: "center" });
        doc.setFontSize(12);
        doc.text(address, 100, 16, { align: "center" });
        doc.setFontSize(15);
        doc.text(`JV Number: ${jv}`, 100, 22, {
          align: "center",
        });
        doc.text(`Date: ${printDate} `, 100, 28, {
          align: "center",
        });

        // Footer
        var str = "Page " + doc.internal.getNumberOfPages();

        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
    });
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  };

  const debit = data?.reduce((prev, next) => prev + next.drAmt, 0);
  const credit = data?.reduce((prev, next) => prev + next.crAmt, 0);

  const [debitSum, setDebitSum] = useState(debit);

  const [creditSum, setCreditSum] = useState(credit);
  return (
    <>
      <CustomModal open={open} handleClose={handleClose} width="1000px">
        <Grid container direction="row-reverse">
          <Grid item sm={1}>
            <Box marginRight={5} marginTop={2} marginBottom={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                onClick={() => handlePrint()}
              >
                Print
              </Button>
            </Box>
          </Grid>
          <Grid item sm={3}>
            <Box
              marginRight={5}
              marginTop={3}
              marginBottom={1}
              style={{ fontSize: "20px", fontWeight: "600" }}
            >
              Jv Number: {jv}
            </Box>
          </Grid>
        </Grid>
        <Paper className={classes.root} elevation={1}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table" id="print-detail">
              <TableHead>
                <TableRow>
                  <TableCell>S.No.</TableCell>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell>{index + 1}</TableCell>
                      {columns.map((column, ind) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
                <TableRow style={{ background: "#c0ddf8" }}>
                  <TableCell></TableCell>
                  <TableCell style={{ fontWeight: "600" }}>Total</TableCell>
                  <TableCell style={{ fontWeight: "600" }}>{debit}</TableCell>
                  <TableCell style={{ fontWeight: "600" }}>{credit}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </CustomModal>
    </>
  );
};

export default DetailModal;
