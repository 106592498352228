import { Box, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { addJournalVoucher, editJournalVoucher } from "../../api/JournalApi";
import CustomCheckbox from "../common/customCheckbox/CustomCheckbox";
import { CustomInput } from "../common/customInput/CustomInput";
import { CustomSelect } from "../common/customSelect/CustomSelect";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  error: {
    color: "red",
    fontSize: "16px",
    padding: "8px 15px",
    borderRadius: "5px",
    background: "#ff000017",
  },
}));

function FinalSubmission({
  getDifference,
  journals,
  setError,
  error,
  singleJV,
  handleClose,
  setJournals,
}) {
  const classes = useStyles();
  const doc = new jsPDF();
  const name = localStorage.getItem("name");
  const address = localStorage.getItem("address");
  const { working_day } = useSelector((state) => state.auth);

  const defaultValues = {
    custJVNo: singleJV?.custJVNo || "",
    narration: singleJV?.narration || "",
    reverse: singleJV?.reverse || "",
    jvType: singleJV?.jvType || "JV",
  };
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const handlePrint = () => {
    doc.autoTable({
      html: "#first-table",

      startY: 28,

      margin: { top: 28 },
      styles: { overflow: "linebreak" },
      bodyStyles: { valign: "top" },
      columnStyles: { email: { columnWidth: "wrap" } },
      headStyles: {
        overflow: "linebreak",
        align: "center",
      },
      theme: "striped",
      showHead: "everyPage",

      didDrawPage: function (data) {
        // Header
        doc.setFontSize(18);
        doc.setTextColor(40);
        doc.text(name, 66, 10);
        doc.setFontSize(12);
        doc.text(address, 80, 16);
        doc.setFontSize(15);
        doc.text(`Date: ${working_day} `, 64, 24);

        // Footer
        var str = "Page " + doc.internal.getNumberOfPages();

        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
    });
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  };

  const onSubmit = (values) => {
    if (singleJV) {
      const finalValue = { ...values, ledgers: journals, id: singleJV?.id };
      editJournalVoucher(finalValue).then((res) => {
        reset();
        setJournals([]);
      });
    } else if (getDifference() === 0) {
      const finalValue = { ...values, ledgers: journals };
      addJournalVoucher(finalValue).then((res) => {
        reset();
        setJournals([]);
      });
    } else {
      setError("Difference Need to be Zero");
    }
  };

  const handleClick = () => {
    setJournals([]);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container spacing={1} justifyContent="flex-start">
        <Grid item sm={4}>
          <CustomSelect
            label="JV Type"
            control={control}
            errors={errors}
            name="jvType"
            options={[
              {
                label: "JV",
                value: "JV",
              },
              {
                label: "OV",
                value: "OV",
              },
              {
                label: "PV",
                value: "PV",
              },
              {
                label: "RV",
                value: "RV",
              },
            ]}
          />
        </Grid>
        <Grid item sm={4}>
          <CustomInput
            label="Custom JV No"
            control={control}
            errors={errors}
            name="custJVNo"
            type="number"
          />
        </Grid>
        <Grid item sm={4}>
          <CustomCheckbox
            name="reverse"
            errors={errors}
            control={control}
            label="Reverse"
          />
        </Grid>
        <Grid item sm={12}>
          <CustomInput
            label="Remarks"
            name="narration"
            control={control}
            errors={errors}
          />
        </Grid>
        {error && (
          <Grid item sm={12}>
            <div className={classes.error}>{error}</div>
          </Grid>
        )}
        <Grid item sm={2}>
          <Box marginRight={5}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              type="submit"
            >
              Submit
            </Button>
          </Box>
        </Grid>
        <Grid item sm={3}>
          <Box marginRight={5}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              type="submit"
              onClick={() => handlePrint()}
            >
              Submit & Print
            </Button>
          </Box>
        </Grid>
        <Grid item sm={2}>
          <Box marginRight={5}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              size="large"
              onClick={handleClick}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}

export default FinalSubmission;
