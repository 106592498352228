var DateConverter = (function () {
  "use strict";
  const J = [
    "MM-DD-YYYY",
    "MM/DD/YYYY",
    "YYYY-MM-DD",
    "YYYY/MM/DD",
    "DD-MM-YYYY",
    "DD/MM/YYYY",
  ];

  var c = [],
    m = { year: 2000, month: 9, day: 17 },
    h = { year: 1944, month: 1, day: 1 };
  c[1970] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[1971] = [31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30];
  c[1972] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];
  c[1973] = [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31];
  c[1974] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[1975] = [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30];
  c[1976] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];
  c[1977] = [30, 32, 31, 32, 31, 31, 29, 30, 29, 30, 29, 31];
  c[1978] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[1979] = [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30];
  c[1980] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];
  c[1981] = [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30];
  c[1982] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[1983] = [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30];
  c[1984] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];
  c[1985] = [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30];
  c[1986] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[1987] = [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30];
  c[1988] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];
  c[1989] = [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30];
  c[1990] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[1991] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30];
  c[1992] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31];
  c[1993] = [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30];
  c[1994] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[1995] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30];
  c[1996] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31];
  c[1997] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[1998] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[1999] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];
  c[2000] = [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31];
  c[2001] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2002] = [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30];
  c[2003] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];
  c[2004] = [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31];
  c[2005] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2006] = [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30];
  c[2007] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];
  c[2008] = [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31];
  c[2009] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2010] = [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30];
  c[2011] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];
  c[2012] = [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30];
  c[2013] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2014] = [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30];
  c[2015] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];
  c[2016] = [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30];
  c[2017] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2018] = [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30];
  c[2019] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31];
  c[2020] = [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2021] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2022] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30];
  c[2023] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31];
  c[2024] = [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2025] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2026] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];
  c[2027] = [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31];
  c[2028] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2029] = [31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30];
  c[2030] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];
  c[2031] = [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31];
  c[2032] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2033] = [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30];
  c[2034] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];
  c[2035] = [30, 32, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31];
  c[2036] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2037] = [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30];
  c[2038] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];
  c[2039] = [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30];
  c[2040] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2041] = [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30];
  c[2042] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];
  c[2043] = [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30];
  c[2044] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2045] = [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30];
  c[2046] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];
  c[2047] = [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2048] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2049] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30];
  c[2050] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31];
  c[2051] = [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2052] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2053] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30];
  c[2054] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31];
  c[2055] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2056] = [31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30];
  c[2057] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];
  c[2058] = [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31];
  c[2059] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2060] = [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30];
  c[2061] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];
  c[2062] = [30, 32, 31, 32, 31, 31, 29, 30, 29, 30, 29, 31];
  c[2063] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2064] = [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30];
  c[2065] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];
  c[2066] = [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31];
  c[2067] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2068] = [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30];
  c[2069] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];
  c[2070] = [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30];
  c[2071] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2072] = [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30];
  c[2073] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];
  c[2074] = [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2075] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2076] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30];
  c[2077] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31];
  c[2078] = [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2079] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];
  c[2080] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30];
  c[2081] = [31, 31, 32, 32, 31, 30, 30, 30, 29, 30, 30, 30];
  c[2082] = [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30];
  c[2083] = [31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30];
  c[2084] = [31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30];
  c[2085] = [31, 32, 31, 32, 30, 31, 30, 30, 29, 30, 30, 30];
  c[2086] = [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30];
  c[2087] = [31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30];
  c[2088] = [30, 31, 32, 32, 30, 31, 30, 30, 29, 30, 30, 30];
  c[2089] = [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30];
  c[2090] = [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30];
  c[2091] = [31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30];
  c[2092] = [30, 31, 32, 32, 31, 30, 30, 30, 29, 30, 30, 30];
  c[2093] = [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30];
  c[2094] = [31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30];
  c[2095] = [31, 31, 32, 31, 31, 31, 30, 29, 30, 30, 30, 30];
  c[2096] = [30, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30];
  c[2097] = [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30];
  c[2098] = [31, 31, 32, 31, 31, 31, 29, 30, 29, 30, 29, 31];
  c[2099] = [31, 31, 32, 31, 31, 31, 30, 29, 29, 30, 30, 30];

  function ConvertToDateObject(e, t) {
    var n = [],
      r = { year: null, month: null, day: null };
    switch (t) {
      case "MM/DD/YYYY":
        n = e.split("/");
        3 === n.length &&
          (r = {
            year: Number(n[2]),
            month: Number(n[0]),
            day: Number(n[1]),
          });
        break;
      case "MM-DD-YYYY":
        n = e.split("-");
        3 === n.length &&
          (r = {
            year: Number(n[2]),
            month: Number(n[0]),
            day: Number(n[1]),
          });
        break;
      case "YYYY-MM-DD":
        n = e.split("-");
        3 === n.length &&
          (r = {
            year: Number(n[0]),
            month: Number(n[1]),
            day: Number(n[2]),
          });
        break;
      case "YYYY/MM/DD":
        n = e.split("/");
        3 === n.length &&
          (r = {
            year: Number(n[0]),
            month: Number(n[1]),
            day: Number(n[2]),
          });
        break;
      case "DD-MM-YYYY":
        n = e.split("-");
        3 === n.length &&
          (r = {
            year: Number(n[2]),
            month: Number(n[1]),
            day: Number(n[0]),
          });
        break;
      case "DD/MM/YYYY":
        n = e.split("/");
        3 === n.length &&
          (r = {
            year: Number(n[2]),
            month: Number(n[1]),
            day: Number(n[0]),
          });
        break;
      default:
        n = e.split("-");
        3 === n.length &&
          (r = {
            year: Number(n[0]),
            month: Number(n[1]),
            day: Number(n[2]),
          });
        break;
    }
    return (
      ((r && NaN === r.year) ||
        null === r.year ||
        NaN === r.month ||
        null === r.month ||
        NaN === r.day ||
        null === r.day) &&
        (r = null),
      r
    );
  }

  function ConvertDateFormat(e, t) {
    function n(e) {
      return (e = Number(e)), e < 10 ? "0" + e : e;
    }
    var r = "";
    switch ((t = t && J.indexOf(t) > -1 ? t : "YYYY-MM-DD")) {
      case "MM/DD/YYYY":
        r = n(e.month) + "/" + n(e.day) + "/" + e.year;
        break;
      case "MM-DD-YYYY":
        r = n(e.month) + "-" + n(e.day) + "-" + e.year;
        break;
      case "YYYY-MM-DD":
        r = e.year + "-" + n(e.month) + "-" + n(e.day);
        break;
      case "YYYY/MM/DD":
        r = e.year + "/" + n(e.month) + "/" + n(e.day);
        break;
      case "DD-MM-YYYY":
        r = n(e.day) + "-" + n(e.month) + "-" + e.year;
        break;
      case "DD/MM/YYYY":
        r = n(e.day) + "/" + n(e.month) + "/" + e.year;
        break;
      default:
        r = e.year + "-" + n(e.month) + "-" + n(e.day);
    }
    return r;
  }

  // sum array of passed  year
  function n(e) {
    var t = 0;
    return (
      e.forEach(function (e) {
        t += e;
      }),
      t
    );
  }

  // returns AD days count
  function a(e, t) {
    // Date.UTC returns the number of milliseconds between a date and January 1, 1970
    var n = Date.UTC(e.year, e.month - 1, e.day),
      r = Date.UTC(t.year, t.month - 1, t.day);
    // 1000*60*60*24 or 86400000 or 864e5
    return Math.abs((r - n) / 864e5);
  }

  // returns BS days count
  function o(e, t) {
    var r = 0,
      a = 0;
    for (a = e.year; a <= t.year; a += 1) r += n(c[a]);
    for (a = 0; a < e.month; a += 1) r -= c[e.year][a];
    for (r += c[e.year][11], a = t.month - 1; a < 12; a += 1) r -= c[t.year][a];
    r -= e.day + 1;
    r += t.day - 1;
    return r;
  }

  // add days in AD
  function i(e, t) {
    var n = new Date(ConvertDateFormat(e, "MM/DD/YYYY"));
    return (
      n.setDate(n.getDate() + t),
      { year: n.getFullYear(), month: n.getMonth() + 1, day: n.getDate() }
    );
  }

  // add days in BS
  function u(e, t) {
    console.log(e, t, "eeeeeee");
    e.day += t;
    for (let i = 0; e.day > c[e.year][e.month - 1]; i++) {
      e.day -= c[e.year][e.month - 1];
      e.month += 1;
      if (e.month > 12) {
        e.month = 1;
        e.year += 1;
      }
    }
    return { year: e.year, month: e.month, day: e.day };
  }

  // Convert BS to AD
  function BS2AD(e) {
    var t = o(m, e);
    return i(h, t);
  }

  // convert AD to BS
  function AD2BS(e) {
    var t = a(h, e);
    return u({ ...m }, t);
  }

  return {
    ConvertToDateObject: ConvertToDateObject,
    ConvertDateFormat: ConvertDateFormat,
    AD2BS: AD2BS,
    BS2AD: BS2AD,
  };
})();

export default DateConverter;
