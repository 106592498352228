import { Box, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { getBankMapping } from "../../api/AccountApi";
import { getCustomers, getStaffs, getVendors } from "../../api/MemberApi";
import { postReceipt } from "../../api/ReceiptApi";
import { CustomInput } from "../common/customInput/CustomInput";
import { CustomSelect } from "../common/customSelect/CustomSelect";
import { SearchableSelect } from "../common/searchableSelect/SearchableSelect";
import { motion } from "framer-motion";
import CustomTextarea from "../common/customTextarea/CustomTextarea";
import LoadingButton from "../common/loadingButton/LoadingButton";
import { getSchema } from "../../api/FeeSchema";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
  },
}));

function CreateReceipt({ setLoading, loading }) {
  const classes = useStyles();
  const [banks, setbanks] = useState([]);
  const [payee, setPayee] = useState([]);
  const [schema, setSchema] = useState([]);

  const defaultValues = {
    cashAmount: 0,
    bankAmount: 0,
    customReceiptNo: "",
    chequeAmount: 0,
    chequeNumber: "",
    remarks: "",
    memberId: 0,
    brIds: 0,
    bankAccId: 0,
    chequeBankAccId: 0,
    payment_type: "cash",
    payee_type: "",
    feeSchemeId: "",
    creditAmount: 0,
  };
  const {
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues });

  useEffect(() => {
    getBankMapping().then((res) => setbanks(res.data));
    getSchema().then((res) => setSchema(res.data));
  }, []);
  const onSubmit = (values) => {
    setLoading(true);
    const amount =
      values?.cashAmount || values?.bankAmount || values.chequeAmount;
    const finalvalue = {
      ...values,
      payment: false,
      feeSchemes: [
        {
          feeSchemeId: values.feeSchemeId,
          amount: amount,
          remarks: values.remarks,
        },
      ],
    };
    postReceipt(finalvalue)
      .then(() => {
        setLoading(false);
        reset();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const payment = watch("payment_type");
  const payee_type = watch("payee_type");
  console.log(schema, "schemax");

  useEffect(() => {
    if (payee_type === "vendor") {
      getVendors().then((res) => setPayee(res.data));
    } else if (payee_type === "staff") {
      getStaffs().then((res) => setPayee(res.data));
    } else if (payee_type === "customer") {
      getCustomers().then((res) => setPayee(res.data));
    }
  }, [payee_type]);

  return (
    <motion.div
      className={classes.root}
      initial={{ opacity: 0, x: "-55%", scale: 0 }}
      animate={{
        scale: 1,
        opacity: 1,
        x: 0,
      }}
      transition={{ duration: 0.5 }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item sm={3}>
            <CustomSelect
              label="Payment Type"
              control={control}
              errors={errors}
              name="payment_type"
              options={[
                { label: "Cash", value: "cash" },
                { label: "Bank Transfer", value: "bank" },
                { label: "Cheque", value: "cheque" },
              ]}
            />
          </Grid>
          <Grid item sm={3}>
            <CustomSelect
              label="Payee Type"
              control={control}
              errors={errors}
              name="payee_type"
              options={[
                { label: "Staff", value: "staff" },
                { label: "Vendor", value: "vendor" },
                { label: "Customer", value: "customer" },
              ]}
            />
          </Grid>

          <Grid item sm={3}>
            <SearchableSelect
              options={payee?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              label="Payee"
              control={control}
              errors={errors}
              name="memberId"
              watch={watch}
            />
          </Grid>
          <Grid item sm={3}>
            <SearchableSelect
              options={schema
                ?.filter((list) => list?.payment === false)
                ?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
              label="Schema"
              watch={watch}
              control={control}
              errors={errors}
              name="feeSchemeId"
            />
          </Grid>
          {payment === "cash" && (
            <Grid item sm={3}>
              <CustomInput
                label="Cash Amount"
                control={control}
                errors={errors}
                name="cashAmount"
                type="number"
                min="0"
              />
            </Grid>
          )}
          {payment === "bank" && (
            <>
              <Grid item sm={3}>
                <CustomInput
                  label="Bank Amount"
                  control={control}
                  errors={errors}
                  name="bankAmount"
                  type="number"
                  min="0"
                />
              </Grid>
              <Grid item sm={3}>
                <CustomSelect
                  label="Bank Account"
                  control={control}
                  errors={errors}
                  name="bankAccId"
                  options={banks?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Grid>
            </>
          )}
          {payment === "cheque" && (
            <>
              <Grid item sm={3}>
                <CustomInput
                  type="number"
                  label="Cheque Amount"
                  control={control}
                  errors={errors}
                  name="chequeAmount"
                  min="0"
                />
              </Grid>
              <Grid item sm={3}>
                <CustomInput
                  label="Cheque Number"
                  control={control}
                  errors={errors}
                  name="chequeNumber"
                />
              </Grid>
              <Grid item sm={3}>
                <CustomSelect
                  label="Bank Account"
                  control={control}
                  errors={errors}
                  name="chequeBankAccId"
                  options={banks?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Grid>
            </>
          )}
        </Grid>

        <Grid container spacing={4}>
          <Grid item sm={12}>
            <CustomTextarea
              label="Remarks"
              control={control}
              name="remarks"
              errors={errors}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item sm={12}>
            <Box marginTop={2} display="flex" justifyContent="flex-end">
              <LoadingButton
                variant="contained"
                color="primary"
                // fullWidth
                size="large"
                type="submit"
                loading={loading}
              >
                Submit
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </motion.div>
  );
}

export default CreateReceipt;
