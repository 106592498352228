import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ViewCustomer from "./ViewCustomer";
import ViewVendor from "./ViewVendor";
import { getCustomers, getStaffs, getVendors } from "../../api/MemberApi";
import CreateMember from "./CreateMember";
import ViewStaff from "./ViewStaff";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    flexGrow: 1,
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.primary.main,
    },
    "& .MuiTabs-root": {
      backgroundColor: theme.palette.background.main,
    },
  },
}));

function MemberContainer({ account }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [customers, setCustomer] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [staff, setStaff] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleLoading = (status) => {
    if (status) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCustomers().then((res) => setCustomer(res.data));
    getVendors().then((res) => setVendor(res.data));
    getStaffs().then((res) => setStaff(res.data));
  }, []);
  return (
    <>
      <CreateMember
        account={account}
        setCustomer={setCustomer}
        setVendor={setVendor}
        handleLoading={handleLoading}
      />

      <div className={classes.root}>
        <AppBar position="static" color="none" elevation={0}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Customer" {...a11yProps(0)} />
            <Tab label="Vendor" {...a11yProps(1)} />
            <Tab label="Staff" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        {loading ? (
          <h2>Loading ...</h2>
        ) : (
          <>
            <TabPanel value={value} index={0}>
              <ViewCustomer setCustomer={setCustomer} customers={customers} />
            </TabPanel>

            <TabPanel value={value} index={1}>
              <ViewVendor vendor={vendor} setVendor={setVendor} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ViewStaff staff={staff} setStaff={setStaff} />
            </TabPanel>
          </>
        )}
      </div>
    </>
  );
}

export default MemberContainer;
