import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Box, Grid, Typography } from "@material-ui/core";
import FinalSubmission from "./FinalSubmission";
import DeleteIcon from "@material-ui/icons/Delete";
const columns = [
  { id: "name", label: "Account Code", minWidth: 170 },
  { id: "drAmt", label: "Debit Amount", minWidth: 100 },
  {
    id: "crAmt",
    label: "Credit Amount",
    minWidth: 170,
    align: "right",
  },
  {
    id: "remarks",
    label: "Remarks",
    minWidth: 170,
    align: "right",
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 170,
    align: "center",
  },
];

const decLen = 4;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "& .MuiTableCell-stickyHeader": {
      background: theme.palette.background.dark,
      //   color: theme.palette.primary.dark,
    },
    "& .MuiTableBody-root": {
      "& tr:nth-of-type(odd)": {
        background: theme.palette.background.main,
      },
    },
  },
  delete: {
    color: "#ff6363",
    cursor: "pointer",
    "&:hover": {
      color: "red",
    },
  },
  container: {
    maxHeight: 550,
    borderRadius: "5px",
  },
  boxWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.background.main,
    padding: theme.spacing(1.5),
    "& h5": {
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(2),
    },
  },
}));

function ViewJournal({
  journals,
  account,
  setJournals,
  setError,
  error,
  singleJV,
  handleClose,
}) {
  const classes = useStyles();
  const findAccName = (accountHeadId) => {
    let selected = account.find((item) => item.id === accountHeadId);
    return selected?.name;
  };

  const rows = journals.map((item) => ({
    ...item,
    name: findAccName(item.accountHeadId),
  }));

  const getDebitTotal = () => {
    return parseFloat(journals.reduce((acc, journal) => acc + Number(journal.drAmt), 0).toFixed(decLen));
  };

  const getCreditTotal = () => {
    return parseFloat(journals.reduce((acc, journal) => acc + Number(journal.crAmt), 0).toFixed(decLen));
  };

  const getDifference = () => {
    return parseFloat((getDebitTotal() - getCreditTotal()).toFixed(decLen));
  };

  const handleDelete = (index) => {
    const journal = journals.filter((item, ind) => ind !== index);
    setJournals(journal);
  };

  return (
    <>
      <Paper className={classes.root} elevation={1}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table" id="first-table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column, ind) => {
                      const value = row[column.id];
                      return columns.length === ind + 1 ? (
                        <TableCell align={column.align}>
                          <span>
                            <DeleteIcon
                              className={classes.delete}
                              onClick={() => handleDelete(index)}
                            />
                          </span>
                        </TableCell>
                      ) : (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Grid container spacing={1}>
        <Grid item sm={4}>
          <Paper className={classes.boxWrapper} elevation={0}>
            <Typography variant="body1">Total Debit:</Typography>
            <Typography variant="h5">{getDebitTotal()}</Typography>
          </Paper>
        </Grid>
        <Grid item sm={4}>
          <Paper className={classes.boxWrapper} elevation={0}>
            <Typography variant="body1">Total Credit:</Typography>
            <Typography variant="h5">{getCreditTotal()}</Typography>
          </Paper>
        </Grid>
        <Grid item sm={4}>
          <Paper className={classes.boxWrapper} elevation={0}>
            <Typography variant="body1"> Difference:</Typography>
            <Typography variant="h5">{getDifference()}</Typography>
          </Paper>
        </Grid>
      </Grid>
      <FinalSubmission
        handleClose={handleClose}
        singleJV={singleJV}
        journals={journals}
        getDifference={getDifference}
        setError={setError}
        setJournals={setJournals}
        error={error}
      />
    </>
  );
}

export default ViewJournal;
