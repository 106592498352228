import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useEffect } from "react";

import {
  approveJournalVoucher,
  fetchUnapprovedJournalVoucher,
  fetchUnapprovedJournalVoucherByDate,
} from "../../api/JournalApi";
import {
  formatDate,
  formatToUnix,
  getCurrentDate,
} from "../../utils/TimestamptoDate";
import { useStyles } from "./styles";
import CustomModal from "../common/customModal/CustomModal";
import JournalEdit from "./JournalEdit";
import JournalEntry from "../jvEntry/JournalEntry";
import JVEntry from "../jvEntry/Index";

const columns = [
  { id: "jvNo", label: "JV No.", minWidth: 80 },
  { id: "custJVNo", label: "Custom JV No.", minWidth: 140 },
  { id: "jvAmt", label: "JV Amt", minWidth: 100 },
  { id: "status", label: "Status", minWidth: 100 },
  { id: "jvType", label: "JV Type", minWidth: 90 },
  { id: "postedDateAD", label: "Posted Date", minWidth: 120 },
  { id: "narration", label: "Narration", minWidth: 200 },
  { id: "actions", label: "Actions", minWidth: 120 },
];

function ViewJournal({ date = getCurrentDate(), isBackDate }) {
  const classes = useStyles();
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const [singleJV, setSingleJV] = useState();
  const [pageable, setPageable] = useState({
    pageSize: 10,
    pageNumber: 1,
    sort: {
      sorted: true,
      unsorted: true,
      empty: false,
    },
    unpaged: true,
    paged: true,
    offset: 0,
  });

  const fetchData = () => {
    if (date) {
      fetchUnapprovedJournalVoucherByDate(
        formatToUnix(date),
        pageable,
        isBackDate
      ).then((res) => setData(res.data));
    } else {
      fetchUnapprovedJournalVoucher(pageable).then((res) => setData(res.data));
    }
  };

  useEffect(() => {
    fetchData();
  }, [date, isBackDate]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (row) => {
    setSingleJV(row);
    setOpen(true);
  };

  const checkBoolean = (value) => {
    if (value === true) {
      return "True";
    }
    return "False";
  };

  const checkValue = (column, row) => {
    const value = row[column.id];

    if (column.format && typeof value === "number") {
      return column.format(value);
    } else if (typeof value === "boolean") {
      return checkBoolean(value);
    } else if (column?.id === "postedDateAD") {
      return formatDate(value);
    } else if (column?.id === "actions") {
      return (
        <div style={{ display: "flex" }}>
          <span
            className={classes.approveBtn}
            onClick={() => handleApprove(row["id"])}
          >
            Approve
          </span>
          <span className={classes.editBtn} onClick={() => handleOpen(row)}>
            Edit
          </span>
          <span
            className={classes.rejectBtn}
            onClick={() => handleReject(row["id"])}
          >
            Reject
          </span>
        </div>
      );
    } else {
      return value;
    }
  };

  const handleApprove = (jvId) => {
    approveJournalVoucher(jvId, true).then((res) => {
      fetchData();
    });
  };
  const handleReject = (jvId) => {
    approveJournalVoucher(jvId, false).then((res) => {
      fetchData();
    });
  };

  const handleEdit = (jvId) => {};
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Paper className={classes.root} elevation={1}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>S.No</TableCell>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.content?.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    <TableCell> {index + 1}</TableCell>
                    {columns.map((column, ind) => {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {checkValue(column, row)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <CustomModal
        handleClose={handleClose}
        open={open}
        height="90vh"
        width="90%"
      >
        <JournalEdit singleJV={singleJV} handleClose={handleClose} />
      </CustomModal>
    </>
  );
}

export default ViewJournal;
