import React from "react";
import { Controller } from "react-hook-form";
import {
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { useRef } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: 8,
    position: "relative",
    "& label": {
      marginTop: "-12px",
      display: "block",
      marginBottom: "4px",
    },
    "& .nepali-date-picker input": {
      border: "none",
      padding: "15px 14px",
      fontSize: "16px",
      background: "#F4F9FF",
      borderRadius: "4px",
      position: "absolute",
      width: "30px",
      top: "-24px",
      right: "-5px",
      opacity: "0",
      cursor: "pointer",
    },
  },
  fieldWrapper: {
    border: "1px solid gray",
    display: "flex",
    position: "relative",
    lineHeight: "32px",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 8px 8px 20px",
    borderRadius: "4px",
  },
  field: {
    "& .MuiInput-underline:after": {
      border: "none",
    },
    "& .MuiInput-underline:before": {
      border: "none",
    },
    "& .MuiInput-underline:hover:before": {
      border: "none",
    },
  },
}));

export const CustomNepaliDatePicker = ({
  name,
  control,
  type = "text",
  errors,
  placeholder = null,
  label = "",
  fullWidth = true,
  defaultValue,
  rule = { required: false },
  ...props
}) => {
  const classes = useStyles();
  const [date, setDate] = useState();
  const handleCalendar = () => {
    // nepaliDate?.current.click();
    console.log(nepaliDate?.current);
  };
  const nepaliDate = useRef(null);
  return (
    <>
      <div className={classes.root}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field: { onChange, value } }) => (
            <>
              {label && <label className={classes.label}>{label}</label>}
              <Box className={classes.fieldWrapper}>
                <TextField
                  type={type}
                  onChange={onChange}
                  fullWidth={fullWidth}
                  placeholder={placeholder}
                  className={classes.field}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={value}
                  {...props}
                />
                <CalendarTodayIcon onClick={handleCalendar} />
                <NepaliDatePicker
                  inputClassName="form-control"
                  className={classes.nepaliPicker}
                  placeholder={placeholder}
                  ref={nepaliDate}
                  value={value}
                  onChange={onChange}
                  options={{ calenderLocale: "en", valueLocale: "en" }}
                  {...props}
                />
              </Box>
            </>
          )}
          rules={rule}
        />
        {/* {errors[name] && errors[name].type === "required" && (
          <FormHelperText
            style={{ color: "red" }}
          >{`${name} is required`}</FormHelperText>
        )} */}
      </div>
    </>
  );
};
