import * as actions from "./Auth.type";

import {
  userLogin,
  // userValidate,
  // userRegister,
  // userSocialLogin,
  // userlogout,
} from "../../../api/Authapi";
import { getActiveWorkingDay } from "../../../api/DateStatus";
// Login

export const login = (data) => (dispatch) => {
  userLogin(data)
    .then((res) => {
      dispatch({
        type: actions.LOGIN_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);

      dispatch({
        type: actions.LOGIN_FAIL,
      });
    });
};

// Logout
export const logout = () => (dispatch) => {
  dispatch({
    type: actions.LOGOUT_SUCCESS,
  });
};

//auth error
export const authError = () => (dispatch) => {
  dispatch({
    type: actions.AUTH_ERROR,
  });
};

// fetch working day
export const fetchWorkingDay = () => (dispatch) => {
  getActiveWorkingDay().then((res) => {
    dispatch({ type: actions.FETCH_WORKING_DAY, payload: res.data });
  });
};
