import defaultTheme from "./default";

import { createMuiTheme } from "@material-ui/core";

const overrides = {
  typography: {
    fontFamily:['"Poppins"', 'sans-serif'].join(','),

    h1: {
      fontSize: "2.5rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.8rem",
    },
    h4: {
      fontSize: "1.6rem",
    },
    h5: {
      fontSize: "1.4rem",
    },
    h6: {
      fontSize: "1.2rem",
    },
  },
  MuiTypography: {
    gutterBottom: {
      marginBottom: "10px"
    },
  },
};

export default {
  default: createMuiTheme({ ...defaultTheme, ...overrides }),
};
