import React, { useEffect, useState } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchWorkingDay,
  logout,
} from "../../components/auth/redux/Auth.action";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {
  getActiveWorkingDay,
  getPreviousWorkingDay,
} from "../../api/DateStatus";
import { convertToNepali, formatDate } from "../../utils/TimestamptoDate";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#Fff",
    color: "#848484",
    fontWeight: "lighter",
    boxShadow: "0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: "60px",
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    color: "#2d8acd",
    fontSize: "24px",
    fontWeight: "lighter",
    minWidth: "150px",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#61b5f126",
    color: "#000",
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    lineHeight: "46px",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(8),
      width: "40%",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#949494",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    "& .MuiIconButton-root": {
      padding: `${theme.spacing(0)}px ${theme.spacing(2.5)}px`,
      "&:hover": {
        backgroundColor: "transparent",
        color: "#8f88cc",
      },
    },

    fontSize: "16px",
    "& .MuiSvgIcon-root": {
      fontSize: "22px",
    },
  },
  time: {
    fontSize: "14px",
  },
  profileIcon: {
    "&  .MuiSvgIcon-root": {
      fontSize: "40px",
      borderRadius: "50%",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  daySection: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  workingDay: {
    background: "#e7f4fd",
    color: "#398eca",
    padding: "4px 12px",
    fontWeight: "600",
    width: "200px",
    fontSize: "13px",
    borderRadius: "4px 4px 0 0",
  },
  currentDay: {
    background: "#e0e0e0",
    color: "#4c4e4f",
    padding: "4px 12px",
    fontWeight: "600",
    fontSize: "13px",
    width: "200px",
    borderRadius: "0 0 4px 4px",
  },
}));

export default function PrimarySearchAppBar({ setOpen, open, name }) {
  const classes = useStyles(open);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const { working_day } = useSelector((state) => state.auth);
  const [today, setToday] = useState(convertToNepali(formatDate(Date())));

  const isMenuOpen = Boolean(anchorEl);

  const history = useHistory();

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    history.push("/");
  };

  useEffect(() => {
    dispatch(fetchWorkingDay());
  }, [dispatch]);

  // const menuId = "primary-search-account-menu";
  // const renderMenu = (
  //   <Menu
  //     anchorEl={anchorEl}
  //     anchorOrigin={{ vertical: "top", horizontal: "right" }}
  //     id={menuId}
  //     keepMounted
  //     transformOrigin={{ vertical: "top", horizontal: "right" }}
  //     open={isMenuOpen}
  //     onClose={handleMenuClose}
  //   >
  //     <MenuItem onClick={handleMenuClose}>My Profile</MenuItem>
  //     <MenuItem onClick={handleLogout}>Logout</MenuItem>
  //   </Menu>
  // );

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" className={classes.root}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={handleOpen}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            {name}
          </Typography>

          <div className={classes.grow} />
          <div className={classes.daySection}>
            <div className={classes.workingDay}>
              Working Day: <span className={classes.span}>{working_day}</span>{" "}
            </div>
            <div className={classes.currentDay}>
              Today: <span className={classes.span}>{today}</span>{" "}
            </div>
          </div>
          <div className={classes.sectionDesktop}>
            {/* <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge color="secondary" badgeContent={1}>
                <NotificationsNoneOutlinedIcon />
              </Badge>
            </IconButton> */}
            <IconButton
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={handleLogout}
            >
              <ExitToAppIcon />
              <Box marginLeft={1}>
                <Typography variant="body1">Logout</Typography>
              </Box>
            </IconButton>

            {/* <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              className={classes.profileIcon}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircleOutlinedIcon />
            </IconButton> */}
          </div>
        </Toolbar>
      </AppBar>
      {/* {renderMenu} */}
    </div>
  );
}
