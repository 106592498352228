import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import InsideHeader from "../../layout/InsideHeader";
import ViewLedger from "./ViewLedger";
import FilterForm from "./FilterForm";
import { useState } from "react";
import { motion } from "framer-motion";

function Ledger() {
  const [ledgers, setLedgers] = useState([]);
  const [accountName, setAccountName] = useState();
  const [dates, setDates] = useState({ from: "", to: "" });
  return (
    <div>
      <InsideHeader title="Ledger">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link color="inherit" href="/">
            Report
          </Link>
          <Typography color="textPrimary">Ledger</Typography>
        </Breadcrumbs>{" "}
      </InsideHeader>
      <motion.div
        initial={{ opacity: 0, x: "-55%", scale: 0 }}
        animate={{
          scale: 1,
          opacity: 1,
          x: 0,
        }}
        transition={{ duration: 0.5 }}
      >
        <FilterForm
          setLedgers={setLedgers}
          setAccountName={setAccountName}
          setDates={setDates}
        />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: "-55%", scale: 0 }}
        animate={{
          scale: 1,
          opacity: 1,
          x: 0,
        }}
        transition={{ duration: 0.5 }}
      >
        <ViewLedger ledgers={ledgers} accountName={accountName} dates={dates} />
      </motion.div>
    </div>
  );
}

export default Ledger;
