import { axiosInstance } from "../utils/AxiosInstance";

export const getPreviousWorkingDay = () => {
  return axiosInstance().get(
    `/transactions/admin/day-status/previous-working-day`
  );
};

export const getActiveWorkingDay = () => {
  return axiosInstance().get(
    `/transactions/admin/day-status/active-working-day`
  );
};
