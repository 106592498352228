import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { login } from "../redux/Auth.action";
import { useForm } from "react-hook-form";
import CustomButton from "../../common/customButton/CustomButton";
import * as yup from "yup";
import { CustomInput } from "../../common/customInput/CustomInput";
import styles from "./Style";
import { Box } from "@material-ui/core";
import CustomInputPassword from "../../common/customInput/CustomInputPassword";

const initialValues = {
  userName: "",
  password: "",
  x_tenantId: "",
};

const validationSchema = yup.object({
  userName: yup.string("Enter your Username").required("Username is required"),
  password: yup
    .string("Enter your password")
    .min(6, "Password should be of minimum 6 characters length")
    .required("Password is required"),
});

export default function Login() {
  const classes = styles();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const history = useHistory();
  const dispatch = useDispatch();
  const [errMessage, setErrMessage] = useState();
  const error = useSelector((state) => state.error);
  const auth = useSelector((state) => state.auth);

  const onSubmit = (values) => {
    localStorage.setItem("xTenantId", values?.xTenantId);
    dispatch(login(values));
    // setSubmitting(false);
  };

  // useEffect(() => {
  //   if (error.id === "LOGIN_FAIL") {
  //     setErrMessage(error.message.message);
  //   } else {
  //     setErrMessage("");
  //   }
  //   if (auth.isAuthenticated) {
  //     history.push("/");
  //   }
  // }, [error.message, auth.isAuthenticated]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.loginForm}>
        <CustomInput
          type="text"
          label="Username"
          name="userName"
          margin="normal"
          control={control}
          errors={errors}
          fullWidth
        />
        <Box marginTop={2} marginBottom={2}>
          <CustomInputPassword
            type="password"
            label="Password"
            name="password"
            margin="normal"
            errors={errors}
            control={control}
            fullWidth
          />
        </Box>
        <CustomInput
          type="text"
          label="X-TenantID"
          name="xTenantId"
          margin="normal"
          control={control}
          errors={errors}
          fullWidth
        />
        <div className={classes.buttonWrapper}>
          <CustomButton type="submit" fullWidth spinner={true}>
            SignIn
          </CustomButton>
        </div>
      </form>
    </>
  );
}
