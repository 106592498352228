import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import InsideHeader from "../../layout/InsideHeader";
import FilterForm from "../trialBalance/FilterForm";
import ViewCash from "./ViewCash";
import { getDayCloseSummary } from "../../api/AccountApi";
import { getActiveWorkingDay } from "../../api/DateStatus";

function CashInOut() {
  const [summary, setSummary] = useState([]);
  const [activeDate, setActiveDate] = useState();

  useEffect(() => {
    getDayCloseSummary().then((res) => setSummary(res.data));
    getActiveWorkingDay().then((res) => {
      setActiveDate(res?.data?.dayStartDateBS);
    });
  }, []);

  return (
    <div>
      <InsideHeader title="Day Close Summary">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link color="inherit" href="/">
            Report
          </Link>
          <Typography color="textPrimary">Day Close Summary</Typography>
        </Breadcrumbs>{" "}
      </InsideHeader>
      <ViewCash summary={summary} activeDate={activeDate} />
    </div>
  );
}

export default CashInOut;
