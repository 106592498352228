import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import { deleteAccountHead, fetchAllAccount } from "../../api/AccountApi";
import { useEffect } from "react";
import { getTransactions } from "../../api/TaxApi";
import { useState } from "react";
import { getSchema } from "../../api/FeeSchema";

const columns = [
  { id: "name", label: "Name", minWidth: 200 },

  { id: "rate", label: "Rate ", minWidth: 60 },
  {
    id: "accHeadId",
    label: "Account Name",
    minWidth: 100,
    align: "center",
  },
  {
    id: "taxId",
    label: "Tax Id",
    minWidth: 120,
    align: "center",
  },
  {
    id: "payment",
    label: "Payment",
    minWidth: 120,
    align: "center",
  },
  {
    id: "action",
    label: "Action",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    "& .MuiTableCell-stickyHeader": {
      background: theme.palette.background.dark,
      //   color: theme.palette.primary.dark,
    },
    "& .MuiTableBody-root": {
      "& tr:nth-of-type(odd)": {
        background: theme.palette.background.main,
      },
    },
  },
  delete: {
    color: "#ff6363",
    cursor: "pointer",
    "&:hover": {
      color: "red",
    },
  },
  container: {
    maxHeight: 550,
    borderRadius: "5px",
  },
}));

function ViewFeeSchema({ loading, schema, setSchema }) {
  const classes = useStyles();

  const rows = schema;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDelete = (id) => {
    console.log(id, "index");
  };

  useEffect(() => {
    getSchema().then((res) => setSchema(res.data));
  }, []);

  return loading ? (
    <h2>loading...</h2>
  ) : (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>S.No.</TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    <TableCell>{index + 1}</TableCell>
                    {columns.map((column, ind) => {
                      const value = row[column.id];
                      return columns?.length !== ind + 1 ? (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : String(value)}
                        </TableCell>
                      ) : (
                        <TableCell align={column.align}>
                          <span>
                            <DeleteIcon
                              className={classes.delete}
                              onClick={() => handleDelete(row?.id)}
                            />
                          </span>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default ViewFeeSchema;
