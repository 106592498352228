import { Box, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useForm } from "react-hook-form";
import { addMembers, getCustomers, getVendors } from "../../api/MemberApi";

import { CustomInput } from "../common/customInput/CustomInput";
import { CustomSelect } from "../common/customSelect/CustomSelect";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
  },
}));

function CreateMember({ setCustomer, setVendor, handleLoading }) {
  const classes = useStyles();

  const defaultValues = {
    name: "",
    type: "",
  };
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues });

  const handleSuccess = (val) => {
    if (val === true) {
      getVendors().then((res) => setVendor(res.data));
    } else {
      getCustomers().then((res) => setCustomer(res.data));
    }
  };
  const onSubmit = (values) => {
    // const finalValue = () => {
    //   if (values?.vendor) {
    //     return { ...values, acc_id: 39 };
    //   } else {
    //     return { ...values, acc_id: 37 };
    //   }
    // };
    handleLoading(true);
    addMembers(values)
      .then((res) => {
        reset();
        handleSuccess(values?.vendor);
        handleLoading(false);
      })
      .catch(() => {
        handleLoading(false);
      });

    console.log(values);
  };

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item sm={3}>
            <CustomInput
              label="Member Name"
              control={control}
              errors={errors}
              name="name"
            />
          </Grid>
          <Grid item sm={3}>
            <CustomSelect
              label="Member Type"
              control={control}
              errors={errors}
              name="type"
              options={[
                { label: "Vendor", value: "VENDOR" },
                { label: "Customer", value: "CUSTOMER" },
                { label: "Staff", value: "STAFF" },
              ]}
            />
          </Grid>
          <Grid item sm={2}>
            <Box marginTop={2} marginLeft={5}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                type="submit"
              >
                Add
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default CreateMember;
