import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import InsideHeader from "../../layout/InsideHeader";
import { useEffect } from "react";
import { fetchAllAccount } from "../../api/AccountApi";
import CreateTax from "./Create";
import ViewTax from "./View";
import CreateFeeSchema from "./Create";
import ViewFeeSchema from "./View";
import { getTransactions } from "../../api/TaxApi";
import { motion } from "framer-motion";

function FeeSchema() {
  const [tax, setTax] = useState([]);
  const [loading, setLoading] = useState("");
  const [schema, setSchema] = useState([]);

  useEffect(() => {
    getTransactions().then((res) => setTax(res.data));
  }, []);
  return (
    <div>
      <InsideHeader title="FeeSchema">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link color="inherit" href="/">
            Home
          </Link>

          <Typography color="textPrimary">Fee Schema</Typography>
        </Breadcrumbs>{" "}
      </InsideHeader>
      <motion.div
        initial={{ opacity: 0, x: "-55%", scale: 0 }}
        animate={{
          scale: 1,
          opacity: 1,
          x: 0,
        }}
        transition={{ duration: 0.5 }}
      >
        <CreateFeeSchema
          tax={tax}
          setSchema={setSchema}
          setLoading={setLoading}
          loading={loading}
        />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: "-55%", scale: 0 }}
        animate={{
          scale: 1,
          opacity: 1,
          x: 0,
        }}
        transition={{ duration: 0.5 }}
      >
        <ViewFeeSchema
          loading={loading}
          schema={schema}
          setSchema={setSchema}
        />
      </motion.div>
    </div>
  );
}

export default FeeSchema;
