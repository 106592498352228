import { Box, Container, CssBaseline, makeStyles } from "@material-ui/core";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import React, { useEffect, useState } from "react";
import Footer from "./footer/Footer";
import Navbar from "./header/Navbar";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";
import { fetchProfileApi } from "../api/DashboardApi";

const useStyles = makeStyles((theme, open) => ({
  dashboard: {
    backgroundColor: theme.palette.common.white,
  },
  content: {
    flexGrow: 1,
    display: "flex",
    paddingTop: theme.spacing(8),
    minHeight: "100vh",
    overflow: "hidden",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    padding: `${theme.spacing(0)}px ${theme.spacing(4.2)}px`,
    marginLeft: (open) => (open ? "120px" : "0px"),
    transition: "all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    maxWidth: "100%",
  },
  contentHeader: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "38px",
    alignItems: "center",
  },
  profileIcon: {
    "& .MuiSvgIcon-root": {
      fontSize: "2rem",
      color: "gray",
    },
  },
  contentTitle: {
    fontSize: "24px",
    fontWeight: 600,
    marginLeft: "15px",
    color: "#48484a",
    position: "relative",
    display: "inline-block",
  },
  titleBadge: {
    top: "0px",
    right: "-40px",
    position: "absolute",
    lineHeight: "16px",
    fontSize: "10px",
    borderRadius: "3px",
    color: "#424296",
    padding: "0px 5px",
    background: "#bcc4ea",
  },
  contentSubtitle: {
    color: "#48484a",
    fontSize: "12px",
    marginLeft: "15px",
  },
}));

const DashboardLayout = ({ children }) => {
  const [open, setOpen] = useState(true);
  const classes = useStyles(open);
  const [name, setName] = useState();

  useEffect(() => {
    fetchProfileApi().then((res) => {
      localStorage.setItem("name", res.data?.name);
      setName(res.data?.name);
      localStorage.setItem("address", res.data?.address);
    });
  }, []);

  return (
    <>
      <Box className={classes.dashboard}>
        <Navbar open={open} setOpen={setOpen} name={name} />
        <main className={classes.content}>
          <Sidebar open={open} setOpen={setOpen} />
          <Container className={classes.container}>
            {/* <Box className={classes.contentHeader}>
              <Box className={classes.profileIcon}>
                <PermIdentityOutlinedIcon />
              </Box>
              <Box>
                <Box className={classes.contentTitle}>
                  Hi {authState?.user?.username}
                  <Box className={classes.titleBadge}>User</Box>
                </Box>
                <Box className={classes.contentSubtitle}>
                  You have 5 more tasks ready for today.
                </Box>
              </Box>
           
            </Box> */}
            {children}
          </Container>
        </main>
        <CssBaseline />
      </Box>
    </>
  );
};
export default DashboardLayout;
