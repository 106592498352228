import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 16,
    "& .MuiOutlinedInput-input": {
      padding: "14px 14px",

      background: theme.palette.background.main,
      borderRadius: theme.spacing(0.5),
    },
    "& .MuiTextField-root": {
      marginTop: theme.spacing(2),
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiFormControl-fullWidth": {
      width: "100%",
    },
    "& .MuiInputLabel-outlined": {
      marginTop: "-26px",
      fontSize: "20px",
      marginLeft: "-10px",
    },
    "& .MuiOutlinedInput-multiline": {
      padding: 0,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, 3px) scale(.75)",
    },
  },
}));

export default function CustomCheckbox({
  name,
  control,
  defaultValue,
  label,
  errors,
}) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={value}
                  onChange={onChange}
                  variant="outlined"
                />
              }
              label={label}
            />
          )}
        />
        <div>
          {errors[name] && errors[name].type === "required" && (
            <FormHelperText
              style={{ color: "#f44336" }}
            >{`${name} is required`}</FormHelperText>
          )}
        </div>
      </div>
    </>
  );
}
