import moment from "moment";
import NepaliFunctions from "./bsToAd";
import DateConverter from "./DateConverter";
export const formatDate = (timestamp, format = "YYYY-MM-DD") => {
  return moment(timestamp).format(format);
};

formatDate.defaultProps = {
  format: "LLL",
};

export const getTimeStampFormat = (date) => {
  return moment.utc(date).format();
};

export const convertToNepali = (date) => {
  if (date) {
    const add = DateConverter.ConvertToDateObject(date, "YYYY-MM-DD");
    const bs = DateConverter.AD2BS(add);
    const finalBs = DateConverter.ConvertDateFormat(bs, "YYYY-MM-DD");
    return finalBs;
  }
};

export const formatToUnix = (date) => {
  const bs = DateConverter.ConvertToDateObject(date, "YYYY-MM-DD");
  const ad = DateConverter.BS2AD(bs);
  const finalAd = DateConverter.ConvertDateFormat(ad, "YYYY-MM-DD");
  let current_time = moment().format("HH:mm:ss");
  return moment(finalAd + " " + current_time).valueOf();
};

export const getCurrentDate = () => {
  return convertToNepali(formatDate(new Date()));
};
