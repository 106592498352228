import { Box, Button, Grid } from "@material-ui/core";
import { SettingsOverscanOutlined } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  addAccountHead,
  fetchAccountById,
  fetchAllAccount,
  findAccountType,
  findMappingPurpose,
  updateAccountHead,
} from "../../api/AccountApi";
import { returnErrors } from "../../redux/Error/Error.action";
import { CustomInput } from "../common/customInput/CustomInput";
import { CustomSelect } from "../common/customSelect/CustomSelect";
import { SearchableSelect } from "../common/searchableSelect/SearchableSelect";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
  },
}));

function CreateAccount({
  account,
  setLoading,
  loading,
  setAccount,
  currentAccount,
  setCurrentAccount,
}) {
  const classes = useStyles();
  const [accType, setAccType] = useState([]);
  const [singleAccount, setSingleAccount] = useState();
  const [mapping, setMapping] = useState([{ cvLbl: "None", id: "" }]);
  const [complete, setComplete] = useState(false);
  const defaultValues = {
    name: singleAccount?.name || "",
    alias: singleAccount?.alias || "",
    type: singleAccount?.type || "income",
    parent: singleAccount?.parent || 0,
    mappingPurposeId: singleAccount?.mappingPurposeId || 0,
    brIds: [0],
    openingAmt: singleAccount?.openingAmt || "",
    jvTrxnStatus: singleAccount?.jvTrxnStatus || true,
    forBranch: true,
  };
  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues });

  const onSubmit = (values) => {
    console.log(values, "val");
    setLoading(true);

    if (!currentAccount) {
      addAccountHead(values)
        .then((res) => {
          reset();
          fetchAllAccount().then((res) => setAccount(res.data));
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      const updatedValue = {
        id: currentAccount,
        ...values,
      };
      updateAccountHead(updatedValue)
        .then((res) => {
          reset();
          fetchAllAccount().then((res) => setAccount(res.data));
          setLoading(false);
          setCurrentAccount("");
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    findAccountType().then((res) => setAccType(res.data));
    findMappingPurpose().then((res) =>
      setMapping((prev) => [...prev, ...res.data])
    );
  }, []);

  useEffect(() => {
    currentAccount &&
      fetchAccountById(currentAccount).then((res) =>
        setSingleAccount(res.data)
      );
  }, [currentAccount]);
  console.log(singleAccount, "currentaccoutn");
  console.log(account, "account");

  useEffect(() => {
    setValue("name", singleAccount?.name || "");
    setValue("alias", singleAccount?.alias || "");
    setValue("type", singleAccount?.typeId);
    setValue("parent", singleAccount?.parentId || 0);
    setValue("mappingPurposeId", singleAccount?.mappingPurposeId || "");
    setValue("openingAmt", singleAccount?.openingAmt || "");
    setValue("jvTrxnStatus", singleAccount?.jvTrxnStatus || true);
  }, [singleAccount]);

  const parent = watch("parent");

  console.log(parent, "parent");

  useEffect(() => {
    if (parent) {
      const typeId = account?.find((item) => item?.id === parent)?.typeId;
      setValue("type", typeId);
    }
  }, [parent]);

  return loading ? (
    <h1>loading</h1>
  ) : (
    <div className={classes.root}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item sm={3}>
            <CustomInput
              label="Account Name"
              control={control}
              errors={errors}
              name="name"
              autoFocus={true}
            />
          </Grid>
          <Grid item sm={3}>
            <SearchableSelect
              options={account?.map((item) => ({
                label: `${item.name}(${item.code})`,
                value: item.id,
              }))}
              watch={watch}
              label="Parent Account Name"
              control={control}
              errors={errors}
              disabled={currentAccount ? true : false}
              name="parent"
            />
          </Grid>
          <Grid item sm={3}>
            <CustomInput
              label="Opening Amount"
              control={control}
              type="number"
              name="openingAmt"
              disabled={currentAccount ? true : false}
              errors={errors}
            />
          </Grid>
          <Grid item sm={3}>
            <CustomInput
              label="Account Alias"
              control={control}
              errors={errors}
              name="alias"
            />
          </Grid>

          <Grid item sm={3}>
            <CustomSelect
              label="Account Type"
              control={control}
              disabled={currentAccount ? true : false}
              errors={errors}
              name="type"
              options={accType?.map((item) => ({
                label: item.cvLbl,
                value: item.cvCode,
              }))}
            />
          </Grid>
          <Grid item sm={3}>
            <CustomSelect
              label="Mapping Purpose "
              control={control}
              disabled={currentAccount ? true : false}
              errors={errors}
              name="mappingPurposeId"
              options={mapping?.map((item) => ({
                label: item?.cvLbl,
                value: item?.id,
              }))}
            />
          </Grid>
          <Grid item sm={4}></Grid>
          <Grid item sm={2}>
            <Box marginTop={1} marginLeft={5}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                type="submit"
              >
                Add
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default CreateAccount;
