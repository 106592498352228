import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { CustomInput } from "../common/customInput/CustomInput";
import { CustomSelect } from "../common/customSelect/CustomSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
}));

function VoucherForm({ setFilterData }) {
  const classes = useStyles();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (values) => {
    console.log(values);
    setFilterData({
      jvType: values.jvType,
      voucherNo: values.VoucherNo,
    });
  };
  useEffect(() => {
    setFilterData([]);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container spacing={4}>
        <Grid item sm={3}>
          <CustomSelect
            label="JV Type"
            control={control}
            errors={errors}
            name="jvType"
            options={[
              {
                label: "JV",
                value: "JV",
              },
              {
                label: "OV",
                value: "OV",
              },
              {
                label: "PV",
                value: "PV",
              },
              {
                label: "RV",
                value: "RV",
              },
            ]}
          />
        </Grid>
        <Grid item sm={3}>
          <CustomInput
            label="Voucher No."
            control={control}
            errors={errors}
            name="VoucherNo"
          />
        </Grid>
        <Grid item sm={2}>
          <Box marginLeft={5} marginTop={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              type="submit"
            >
              Search
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}

export default VoucherForm;
