import { Box, Button, Grid, OutlinedInput, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { fetchAllAccount } from "../../api/AccountApi";
import { fetchAccountBalance } from "../../api/ReportApi";
import { CustomInput } from "../common/customInput/CustomInput";
import CustomTextarea from "../common/customTextarea/CustomTextarea";
import { SearchableSelect } from "../common/searchableSelect/SearchableSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
  },
  inputRoot: {
    // marginTop: 8,
    "& .MuiOutlinedInput-input": {
      padding: "14px 14px",
      background: theme.palette.background.main,
      borderRadius: theme.spacing(0.5),
    },
    "& .MuiTextField-root": {
      marginTop: theme.spacing(2),
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiFormControl-fullWidth": {
      width: "100%",
    },
    "& .MuiInputLabel-outlined": {
      marginTop: "-26px",
      fontSize: "20px",
      marginLeft: "-10px",
    },

    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, 3px) scale(.75)",
    },
  },
}));

function JournalEntry({ setJournals, account, setError }) {
  const classes = useStyles();
  const [balance, setBalance] = useState();
  const [loading, setLoading] = useState(false);
  const defaultValues = {
    accountHeadId: "",
    drAmt: 0,
    crAmt: 0,
    remarks: "",
  };
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  const onSubmit = (values) => {
    setError("");
    if ((values?.drAmt || values?.crAmt) && values?.accountHeadId) {
      setLoading(true);
      setJournals((prev) => [...prev, values]);
      reset();
    }
  };

  const checkBalance = useCallback(() => {
    const id = watch("accountHeadId");
    id && fetchAccountBalance(id).then((res) => setBalance(res.data));
  }, [watch]);

  const keys = watch("accountHeadId");

  useEffect(() => {
    checkBalance();
    setLoading(false);
  }, [keys]);

  return loading ? (
    <h1>loading</h1>
  ) : (
    <div className={classes.root}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item sm={3}>
            <SearchableSelect
              label="Account Name"
              name="accountHeadId"
              autoFocus={true}
              control={control}
              errors={errors}
              watch={watch}
              keys={keys}
              options={[
                { label: "Select Account", value: "" },
                ...account?.map((item) => ({
                  label: `${item.name}(${item.code})`,
                  value: item.id,
                })),
              ]}
            />
          </Grid>
          <Grid item sm={3}>
            <Box className={classes.inputRoot}>
              <TextField
                fullWidth
                placeholder="Account Balance"
                label="Selected Account Balance"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
                value={balance}
                variant="outlined"
              />
            </Box>
          </Grid>

          <Grid item sm={3}>
            <CustomInput
              label="Debit"
              type="number"
              name="drAmt"
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item sm={3}>
            <CustomInput
              label="Credit"
              name="crAmt"
              type="number"
              control={control}
              errors={errors}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item sm={12}>
            <CustomTextarea
              label="Remarks"
              control={control}
              name="remarks"
              errors={errors}
              rows="1"
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item sm={12}>
            <Box marginTop={2} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                // fullWidth
                size="large"
                type="submit"
              >
                Add
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default JournalEntry;
