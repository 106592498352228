import { Box, Grid, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DashboardBox from "./DashboardBox";
import PaymentIcon from "@material-ui/icons/Payment";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import MoneyIcon from "@material-ui/icons/Money";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { useStyles } from "./styles";
import { motion } from "framer-motion";
import { getDashboardDataApi } from "../../api/DashboardApi";

function Dashboard() {
  const [dashboard, setDashboard] = useState();
  const classes = useStyles();
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.1,
      },
    },
  };
  const item = {
    hidden: { x: -30, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
    },
  };

  useEffect(() => {
    getDashboardDataApi().then((res) => setDashboard(res.data));
  }, []);
  return (
    <motion.div initial="hidden" animate="visible" variants={container}>
      <Box marginTop={5}>
        <Grid container spacing={3}>
          <Grid item sm={6} md={4} lg={3} xs={12}>
            <motion.div
              whileHover={{ scale: 1.02, y: -2 }}
              whileTap={{ scale: 0.96 }}
              variants={item}
            >
              <DashboardBox
                title="Received"
                amount={dashboard?.clsRecAmt}
                previousAmt={dashboard?.opnRecAmt}
                icons={<ReceiptIcon className={classes.icon} />}
              />
            </motion.div>
          </Grid>
          <Grid item sm={6} md={4} lg={3} xs={12}>
            <motion.div
              whileHover={{ scale: 1.02, y: -2 }}
              whileTap={{ scale: 0.96 }}
              variants={item}
            >
              <DashboardBox
                title="Paid"
                amount={dashboard?.clsPaidAmt}
                previousAmt={dashboard?.opnPaidAmt}
                icons={<PaymentIcon className={classes.icon} />}
              />
            </motion.div>
          </Grid>
          <Grid item sm={6} md={4} lg={3} xs={12}>
            <motion.div
              whileHover={{ scale: 1.02, y: -2 }}
              whileTap={{ scale: 0.96 }}
              variants={item}
            >
              <DashboardBox
                title="Bank"
                amount={dashboard?.clsBankAmt}
                previousAmt={dashboard?.opnBankAmt}
                icons={<AccountBalanceIcon className={classes.icon} />}
              />
            </motion.div>
          </Grid>
          <Grid item sm={6} md={4} lg={3} xs={12}>
            <motion.div
              whileHover={{ scale: 1.02, y: -2 }}
              whileTap={{ scale: 0.96 }}
              variants={item}
            >
              <DashboardBox
                title="Cash In Hand"
                previousAmt={dashboard?.opnCashAmt}
                amount={dashboard?.clsCashAmt}
                icons={<MoneyIcon className={classes.icon} />}
              />
            </motion.div>
          </Grid>
        </Grid>
      </Box>
    </motion.div>
  );
}

export default Dashboard;
