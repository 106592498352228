import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import {
  fetchApprovedJournalVoucherByDates,
  fetchUnapprovedJournalVoucherByVoucherNo,
} from "../../api/JournalApi";
import { useEffect } from "react";
import { formatDate, formatToUnix } from "../../utils/TimestamptoDate";
import CustomModal from "../common/customModal/CustomModal";
import DetailModal from "./DetailModal";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useSelector } from "react-redux";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useRef } from "react";

const columns = [
  { id: "jvNo", label: "JV No.", minWidth: 80 },
  { id: "custJVNo", label: "Custom JV", minWidth: 100 },
  { id: "jvAmt", label: "JV Amt", minWidth: 100 },
  { id: "status", label: "Status", minWidth: 100 },
  { id: "reverse", label: "Reverse", minWidth: 80 },
  { id: "jvType", label: "JV Type", minWidth: 90 },
  { id: "approvedDateBS", label: "Posted Date", minWidth: 120 },
  { id: "narration", label: "Narration", minWidth: 220 },
  { id: "ledgers", label: "View Detail", minWidth: 100 },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    "& .MuiTableCell-stickyHeader": {
      background: theme.palette.background.dark,
      //   color: theme.palette.primary.dark,
    },
    "& .MuiTableBody-root": {
      "& tr:nth-of-type(odd)": {
        background: theme.palette.background.main,
      },
    },
  },
  lastRow: {
    background: "#c0ddf9",
  },
  container: {
    maxHeight: 550,
    borderRadius: "5px",
  },
  viewBtn: {
    background: theme.palette.primary.main,
    padding: "8px 12px",
    borderRadius: "4px",
    cursor: "pointer",
    color: "#fff",
  },
  boxWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.background.main,
    padding: theme.spacing(1.5),
    "& h5": {
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(2),
    },
  },
}));

function ViewJournal({ filterData }) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [ledger, setLedger] = useState([]);
  const [jv, setJv] = useState();
  const [data, setData] = useState();
  const [printDate, setPrintDate] = useState();
  const [totalPages, setTotalPage] = useState();
  const tableRef = useRef(null);
  const doc = new jsPDF();
  const name = localStorage.getItem("name");
  const address = localStorage.getItem("address");
  const { working_day } = useSelector((state) => state.auth);

  useEffect(() => {
    setData([]);

    if (filterData?.fromDate && filterData?.toDate) {
      fetchApprovedJournalVoucherByDates(
        formatToUnix(filterData?.fromDate),
        formatToUnix(filterData?.toDate),
        { size: totalPages, page: 0 }
      ).then((res) => {
        setData(res.data?.content);
        setTotalPage(res.data.totalPages * res.data.size);
      });
    } else if (filterData?.jvType && filterData?.voucherNo) {
      fetchUnapprovedJournalVoucherByVoucherNo(
        filterData?.jvType,
        filterData?.voucherNo
      ).then((res) => {
        setData([res.data]);
        // setTotalPage(res.data.totalPages * res.data.size);
      });
    }
  }, [filterData, totalPages]);

  const checkBoolean = (value) => {
    if (value === true) {
      return "True";
    }
    return "False";
  };

  const checkValue = (column, value) => {
    if (column.format && typeof value === "number") {
      return column.format(value);
    } else if (typeof value === "boolean") {
      return checkBoolean(value);
    } else if (column?.id === "postedDateAD") {
      return formatDate(value);
    } else if (column?.id === "actions") {
      return (
        <div>
          <span className={classes.viewBtn}>View</span>
        </div>
      );
    } else {
      return value;
    }
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleDetail = (value, dateValue, jvNumber) => {
    setLedger(value);
    setPrintDate(dateValue);
    handleOpen();
    setJv(jvNumber);
  };
  const handlePrint = () => {
    doc.autoTable({
      html: "#first-table",

      startY: 28,

      margin: { top: 28 },
      styles: { overflow: "linebreak" },
      bodyStyles: { valign: "top" },
      columnStyles: { email: { columnWidth: "wrap" } },
      headStyles: {
        overflow: "linebreak",
        align: "center",
      },
      theme: "striped",
      showHead: "everyPage",

      didDrawPage: function (data) {
        // Header
        // doc.setFontSize(18);
        // doc.setTextColor(40);
        // doc.text(name, 66, 10);
        // doc.setFontSize(12);
        // doc.text(address, 80, 16);
        // doc.setFontSize(15);
        // doc.text(
        //   `From: ${filterData.fromDate} To: ${filterData.toDate} `,
        //   64,
        //   24
        // );

        doc.setFontSize(18);
        doc.setTextColor(40);
        doc.text(name, 100, 10, { align: "center" });
        doc.setFontSize(12);
        doc.text(address, 100, 16, { align: "center" });
        doc.setFontSize(15);
        doc.text(
          `JV Report - From: ${filterData.fromDate} To: ${filterData?.toDate}`,
          100,
          22,
          {
            align: "center",
          }
        );

        // Footer
        var str = "Page " + doc.internal.getNumberOfPages();

        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
    });
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Grid container direction="row-reverse">
        <Grid item sm={1}>
          <Box marginLeft={5} display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              onClick={() => handlePrint()}
            >
              Print
            </Button>

            <DownloadTableExcel
              filename={"JV Report - From " + filterData?.fromDate + " To " + filterData?.toDate}
              sheet="JV Report"
              currentTableRef={tableRef.current}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{ width: "160px", marginLeft: "15px" }}
              >
                Export excel{" "}
              </Button>
            </DownloadTableExcel>
          </Box>
        </Grid>
      </Grid>
      <Paper className={classes.root} elevation={1}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table" ref={tableRef}>
            <TableHead>
              <TableRow>
                <TableCell>S.No.</TableCell>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      <TableCell>{page * 10 + index + 1}</TableCell>
                      {columns.map((column, ind) => {
                        const value = row[column.id];
                        return column?.id !== "ledgers" ? (
                          <TableCell key={column.id} align={column.align}>
                            {checkValue(column, value)}
                          </TableCell>
                        ) : (
                          <TableCell key={column.id} align={column.align}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                handleDetail(
                                  value,
                                  row?.approvedDateBS,
                                  row?.jvNo
                                )
                              }
                            >
                              <VisibilityIcon fontSize="small" />
                            </Button>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {/* <Box display="flex" justifyContent="center" padding={1}>
          <Box marginRight={2} onClick={handlePrev}>
            <ChevronLeftIcon />
          </Box>
          <Typography>{page}</Typography>
          <Box marginLeft={2} onClick={handleNext}>
            <ChevronRightIcon />
          </Box>
        </Box> */}
      </Paper>

      <DetailModal
        data={ledger}
        printDate={printDate}
        open={open}
        handleClose={handleClose}
        jv={jv}
      />
      <Box display="none">
        <Paper className={classes.root} elevation={1}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table" id="first-table">
              <TableHead>
                <TableRow>
                  <TableCell>S.No.</TableCell>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      <TableCell>{page * 10 + index + 1}</TableCell>
                      {columns.map((column, ind) => {
                        const value = row[column.id];
                        return column?.id !== "ledgers" ? (
                          <TableCell key={column.id} align={column.align}>
                            {checkValue(column, value)}
                          </TableCell>
                        ) : (
                          <TableCell key={column.id} align={column.align}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                handleDetail(
                                  value,
                                  row?.approvedDateBS,
                                  row?.jvNo
                                )
                              }
                            >
                              <VisibilityIcon fontSize="small" />
                            </Button>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
}

export default ViewJournal;
