import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import InsideHeader from "../../layout/InsideHeader";
import { useEffect } from "react";
import { fetchAllAccount, fetchLeafAccountByCode } from "../../api/AccountApi";
import CreateTax from "./CreateTax";
import ViewTax from "./ViewTax";
import { motion } from "framer-motion";

function Tax() {
  const [account, setAccount] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tax, setTax] = useState([]);

  useEffect(() => {
    fetchLeafAccountByCode(2).then((res) => setAccount(res.data));
  }, []);
  return (
    <div>
      <InsideHeader title="Tax">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link color="inherit" href="/">
            Home
          </Link>

          <Typography color="textPrimary">Tax</Typography>
        </Breadcrumbs>{" "}
      </InsideHeader>
      <motion.div
        initial={{ opacity: 0, x: "-55%", scale: 0 }}
        animate={{
          scale: 1,
          opacity: 1,
          x: 0,
        }}
        transition={{ duration: 0.5 }}
      >
        <CreateTax account={account} setLoading={setLoading} setTax={setTax} />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: "-55%", scale: 0 }}
        animate={{
          scale: 1,
          opacity: 1,
          x: 0,
        }}
        transition={{ duration: 0.5 }}
      >
        <ViewTax
          account={account}
          setAccount={setAccount}
          loading={loading}
          setTax={setTax}
          tax={tax}
        />
      </motion.div>
    </div>
  );
}

export default Tax;
