import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import PrivateRoute from "./navigation/PrivateRoute";
import DashboardIndex from "./pages/Dashboard/Index";
import { useDispatch } from "react-redux";
import { loadUser } from "./components/auth/redux/Auth.action";
import SignIn from "./pages/Home/SignIn";
import PublicRoute from "./navigation/PublicRoute";
import Toaster from "./components/common/toaster/Toaster";
import FullSpinner from "./components/common/spinner/FullSpinner";
import { fetchAllAccount } from "./api/AccountApi";
import { userLogin } from "./api/Authapi";
import { getActiveWorkingDay, getPreviousWorkingDay } from "./api/DateStatus";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <PublicRoute path="/signIn" exact>
            <SignIn />
          </PublicRoute>
          <PrivateRoute path="/">
            <DashboardIndex />
          </PrivateRoute>
        </Switch>
      </Router>
      <Toaster />
      <FullSpinner />
    </>
  );
}

export default App;
