import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import InsideHeader from "../../layout/InsideHeader";
import FilterForm from "./FilterForm";
import ViewBalance from "./ViewTable";
import { useState } from "react";
import { motion } from "framer-motion";

function TrialBalance() {
  const [trialBalance, setTrialBalance] = useState([]);
  const [dates, setDates] = useState({ from: "", to: "" });

  return (
    <div>
      <InsideHeader title="Trial Balance">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link color="inherit" href="/">
            Report
          </Link>
          <Typography color="textPrimary">Trial Balance</Typography>
        </Breadcrumbs>{" "}
      </InsideHeader>
      <motion.div
        initial={{ opacity: 0, x: "-55%", scale: 0 }}
        animate={{
          scale: 1,
          opacity: 1,
          x: 0,
        }}
        transition={{ duration: 0.5 }}
      >
        <FilterForm setTrialBalance={setTrialBalance} setDates={setDates} />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: "-55%", scale: 0 }}
        animate={{
          scale: 1,
          opacity: 1,
          x: 0,
        }}
        transition={{ duration: 0.5 }}
      >
        <ViewBalance trialBalance={trialBalance} dates={dates} />
      </motion.div>
    </div>
  );
}

export default TrialBalance;
