import React, { useEffect, useState } from "react";
import { Box, Breadcrumbs, Typography } from "@material-ui/core";
import { fetchAllAccount } from "../../api/AccountApi";
import JournalEntry from "../jvEntry/JournalEntry";
import ViewJournal from "../jvEntry/ViewJournal";
import InsideHeader from "../../layout/InsideHeader";

function JournalEdit({ singleJV, handleClose }) {
  console.log(singleJV, "singleJv");
  const [journals, setJournals] = useState(singleJV?.ledgers || []);
  const [account, setAccount] = useState([]);
  const [error, setError] = useState("");
  useEffect(() => {
    fetchAllAccount().then((res) => setAccount(res.data));
  }, []);
  return (
    <div>
      <InsideHeader title="Journal Voucher Edit"></InsideHeader>
      <JournalEntry
        setJournals={setJournals}
        account={account}
        setError={setError}
      />
      <ViewJournal
        journals={journals}
        singleJV={singleJV}
        handleClose={handleClose}
        account={account}
        setJournals={setJournals}
        setError={setError}
        error={error}
      />
    </div>
  );
}

export default JournalEdit;
