import { axiosInstance } from "../utils/AxiosInstance";
import { handleError, handleResponse } from "./apiResponse";

export function userLogin(data) {
  return axiosInstance()
    .post("/transactions/user/generate-token", data)
    .then(handleResponse)
    .catch(handleError);
}

export const userRegister = (data) => {
  return axiosInstance()
    .post(`/transactions/user`, data)
    .then(handleResponse)
    .catch(handleError);
};

export const getUsers = () => {
  return axiosInstance().get(`/transactions/user/all`).catch(handleError);
};
// export function userValidate() {
//   return axiosInstance().get("/admin/user/validate");
//   // .then(handleResponse)
//   // .catch(handleError);
// }

// export function userRegister(data) {
//   return axiosInstance().post("/transactions/user", {
//     id: 0,
//     name: "Allstar",
//     email: "superadmin@gmail.com",
//     address: "string",
//     contactNo: "string",
//     gender: "MALE",
//     username: "allstar",
//     password: "password",
//     status: "Active",
//     roleId: 1,
//     designationId: 1,
//     brId: 1,
//     firstLogin: true,
//   });
//   // .then(handleResponse)
//   // .catch(handleError);
// }

// export function userlogout(id) {
//   console.log(id);
//   return axiosInstance().post("/admin/user/logout", { user_id: id });
// }
