import React from "react";
import { Controller } from "react-hook-form";
import { FormHelperText, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: 8,
    "& .MuiOutlinedInput-input": {
      padding: "14px 14px",
      background: theme.palette.background.main,
      borderRadius: theme.spacing(0.5),
    },
    "& .MuiTextField-root": {
      marginTop: theme.spacing(2),
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiFormControl-fullWidth": {
      width: "100%",
    },
    "& .MuiInputLabel-outlined": {
      marginTop: "-26px",
      fontSize: "20px",
      marginLeft: "-10px",
    },

    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, 3px) scale(.75)",
    },
  },
}));

export const CustomInput = ({
  name,
  control,
  type = "text",
  errors,
  placeholder = null,
  label = "",
  autoFocus = false,
  fullWidth = true,
  defaultValue,
  disabled = false,
  rule = { required: false },
  ...props
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field: { onChange, value } }) => (
            <TextField
              type={type}
              onChange={onChange}
              fullWidth={fullWidth}
              autoFocus={autoFocus}
              placeholder={placeholder}
              label={label}
              disabled={disabled}
              InputLabelProps={{
                shrink: true,
              }}
              value={value}
              variant="outlined"
              {...props}
            />
          )}
          rules={rule}
        />
        {errors?.[name] && errors?.[name] && (
          <FormHelperText style={{ color: "red" }}>
            {errors?.[name]?.message}
          </FormHelperText>
        )}
      </div>
    </>
  );
};
