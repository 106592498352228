import {
  Box,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  theme,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    minWidth: theme.spacing(4.5),
  },
  nestedBtn: {
    display: "flex",
    padding: `${theme.spacing(3.5)}px ${theme.spacing(2)}px`,
    alignItems: "center",
    flexDirection: "column",
    borderBottom: "1px solid #e3e3e3",
    "& .MuiTypography-body1": {
      fontSize: "0.8rem",
    },
  },
  collapse: {
    height: "calc(100vh - 60px) !important",
    position: "fixed",
    top: "65px",
    bottom: "0px",
    width: theme.spacing(28),
    background: "white",
    left: "128px",
    zIndex: 4,
    boxShadow: "10px 5px 10px #f5f5f5",
  },
  collapseWrapper: {},
}));

function ListCollapse({ children, label, icon }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    handleClose();
  }, [pathname]);

  return (
    <Box
      className={classes.collapseWrapper}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
    >
      <ListItem button className={classes.nestedBtn}>
        <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
        <ListItemText primary={label} />
        {/* {open ? <ExpandLess /> : <ExpandMore />} */}
      </ListItem>
      <Collapse
        in={open}
        timeout={50}
        unmountOnExit
        className={classes.collapse}
      >
        {children}
      </Collapse>
    </Box>
  );
}

export default ListCollapse;
