import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { getCurrentDate } from "../../utils/TimestamptoDate";
import CustomCheckbox from "../common/customCheckbox/CustomCheckbox";
import { CustomNepaliDatePicker } from "../common/nepalidatepicker/NepaliDatePicker";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
}));

function FilterForm({ setDate, setIsBackDate }) {
  const classes = useStyles();

  const defaultValues = {
    date: getCurrentDate(),
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues });

  const onSubmit = (values) => {
    console.log(values);
    setDate(values?.date);
    setIsBackDate(values?.isBackDate);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container spacing={4}>
        <Grid item sm={3}>
          <CustomNepaliDatePicker
            control={control}
            errors={errors}
            name="date"
          />
        </Grid>
        <Grid item sm={3}>
          <Box marginTop="-10px">
            <CustomCheckbox
              control={control}
              errors={errors}
              name="isBackDate"
              label="isBackDate"
            />
          </Box>
        </Grid>

        <Grid item sm={2}>
          <Box marginLeft={5}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              type="submit"
            >
              Search
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}

export default FilterForm;
