import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    "& .MuiTableCell-stickyHeader": {
      background: theme.palette.background.dark,
      //   color: theme.palette.primary.dark,
    },
    "& .MuiTableBody-root": {
      "& tr:nth-of-type(odd)": {
        background: theme.palette.background.main,
      },
    },
  },
  container: {
    maxHeight: 550,
    borderRadius: "5px",
  },
  approveBtn: {
    background: "#38bf88",
    color: "#fff",
    padding: "8px 0px",
    fontSize: "13px",
    borderRadius: "4px",
    marginRight: "10px",
    cursor: "pointer",
    width: "70px",
    display: "inline-block",
    textAlign: "center",
  },
  rejectBtn: {
    background: "#f44336",
    color: "#fff",
    padding: "8px 0px",
    fontSize: "13px",
    borderRadius: "4px",
    cursor: "pointer",
    width: "70px",
    display: "inline-block",
    textAlign: "center",
    marginRight: "10px",
  },
  editBtn: {
    background: "#2096f5",
    color: "#fff",
    padding: "8px 0px",

    fontSize: "13px",
    borderRadius: "4px",
    cursor: "pointer",
    width: "70px",
    display: "inline-block",
    textAlign: "center",
  },
  boxWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.background.main,
    padding: theme.spacing(1.5),
    "& h5": {
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(2),
    },
  },
}));
