import { FormHelperText, makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: 8,
    "& .MuiOutlinedInput-input": {
      padding: "14px 14px",
      background: theme.palette.background.main,
      borderRadius: theme.spacing(0.5),
    },
    "& .MuiTextField-root": {
      marginTop: theme.spacing(2),
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiFormControl-fullWidth": {
      width: "100%",
    },
    "& .MuiInputLabel-outlined": {
      marginTop: "-26px",
      fontSize: "20px",
      marginLeft: "-10px",
    },
    "& .MuiOutlinedInput-multiline": {
      padding: 0,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, 3px) scale(.75)",
    },
  },
}));

export default function CustomTextarea(props) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <Controller
          name={props.name}
          control={props.control}
          defaultValue={props.defaultValue}
          render={({ field: { onChange, value } }) => (
            <TextField
              id="outlined-multiline-static"
              label="Multiline"
              multiline
              fullWidth
              value={value}
              onChange={onChange}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              rows={props.rows || 4}
              {...props}
            />
          )}
          rules={props.rule}
        />
        <div>
          {props.errors[props.name] &&
            props.errors[props.name].type === "required" && (
              <FormHelperText
                style={{ color: "#f44336" }}
              >{`${props.name} is required`}</FormHelperText>
            )}
        </div>
      </div>
    </>
  );
}

CustomTextarea.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomTextarea.defaultProps = {
  size: "small",
  variant: "outlined",
  defaultValue: "",
  rows: 3,
};
