import { axiosInstance } from "../utils/AxiosInstance";
import { handleError } from "./apiResponse";

export const addTax = (data) => {
  return axiosInstance()
    .post(`/transactions/admin/tax`, data)
    .catch(handleError);
};

export const getTransactions = () => {
  return axiosInstance().get(`/transactions/admin/tax/all`).catch(handleError);
};
