import { combineReducers } from "redux";
import { errorReducer } from "./Error/Error.reducer";
import { authReducer } from "../components/auth/redux/Auth.reducer";
import { successReducer } from "./Success/Success.reducer";
import { modalReducer } from "./Modal/Modal.reducer";
import { loadingReducer } from "./Loading/Loading.reducer";
import { spinnerReducer } from "../components/common/spinner/redux/Reducer";

export const rootReducer = combineReducers({
  error: errorReducer,
  auth: authReducer,
  success: successReducer,
  modal: modalReducer,
  loading: loadingReducer,
  spinner: spinnerReducer
});
