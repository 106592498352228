import { axiosInstance } from "../utils/AxiosInstance";
import { handleError, handleResponse } from "./apiResponse";

export const getDashboardDataApi = () => {
  return axiosInstance()
    .get(`/transactions/common/dashboard`)
    .catch(handleError);
};

export const dayCloseApi = (directClose) => {
  return axiosInstance()
    .post(`/transactions/admin/day-status/day-close?directClose=${directClose}`)
    .then(handleResponse)
    .catch(handleError);
};

export const dayStartApi = (date) => {
  return axiosInstance()
    .post(`/transactions/admin/day-status/day-start?newTxnDate=${date}`)
    .then(handleResponse)
    .catch(handleError);
};

export const fetchProfileApi = () => {
  return axiosInstance()
    .get("/transactions/admin/office-setup")
    .catch(handleError);
};
