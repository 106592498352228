import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6),
    "& .MuiBreadcrumbs-li a": {
      color: theme.palette.paragraph[600],
      textDecoration: "none",
      fontSize: "14px",
    },
    "& .MuiBreadcrumbs-li p": {
      fontSize: "14px",
    },
  },
}));

function InsideHeader({ title, children }) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography variant="h4"> {title}</Typography>
      {children}
    </Box>
  );
}

export default InsideHeader;
