import { axiosInstance } from "../utils/AxiosInstance";
import { formatToUnix } from "../utils/TimestamptoDate";
import { handleError, handleResponse } from "./apiResponse";

// fetch trial balance
export function fetchTrialBalance(filters) {
  const type = filters?.type ? `&type=${filters.type}` : "";
  const level = filters?.level ? `&level=${filters.level}` : "";
  const brId = "&brId=1";
  const brByHeadOfc = "&brByHeadOfc=1";
  const fromDate = filters?.fromDate
    ? `&fromDate=${formatToUnix(filters.fromDate)}`
    : "";
  return axiosInstance()
    .get(
      `/transactions/account/journal-vouchers/trial-balance?toDate=${formatToUnix(
        filters?.toDate
      )}${brId}${brByHeadOfc}${type}${level}${fromDate}`
    )
    .catch(handleError);
}

// fetch profit and loss
export function fetchProfitandLoss(filters) {
  const type = filters?.type ? `&type=${filters.type}` : "";
  const level = filters?.level ? `&level=${filters.level}` : "";
  const brId = "&brId=1";
  const brByHeadOfc = "&brByHeadOfc=1";
  const fromDate = filters?.fromDate
    ? `&fromDate=${formatToUnix(filters.fromDate)}`
    : "";
  return axiosInstance()
    .get(
      `/transactions/account/journal-vouchers/profit-and-loss?toDate=${formatToUnix(
        filters?.toDate
      )}${brId}${brByHeadOfc}${type}${level}${fromDate}`
    )
    .catch(handleError);
}

// fetch balance sheet
export function fetchBalanceSheet(filters) {
  const type = filters?.type ? `&type=${filters.type}` : "";
  const level = filters?.level ? `&level=${filters.level}` : "";
  const brId = "&brId=1";
  const brByHeadOfc = "&brByHeadOfc=1";
  const fromDate = filters?.fromDate
    ? `&fromDate=${formatToUnix(filters.fromDate)}`
    : "";
  return axiosInstance()
    .get(
      `/transactions/account/journal-vouchers/balance-sheet?toDate=${formatToUnix(
        filters?.toDate
      )}${brId}${brByHeadOfc}${type}${level}${fromDate}`
    )
    .catch(handleError);
}

// fetch ledger
export function fetchLedger(filters) {
  const accHeadId = filters?.accHeadId ? `&accHeadId=${filters.accHeadId}` : "";
  const fromDate = filters?.fromDate
    ? `&fromDate=${formatToUnix(filters.fromDate)}`
    : "";

  const detail = `&detail=true`;

  const brByHeadOfc = "&brByHeadOfc=false";
  const brIdByHeadOfc = "&brIdByHeadOfc=1";

  return axiosInstance()
    .get(
      `/transactions/account/journal-vouchers/ledger?toDate=${formatToUnix(
        filters?.toDate
      )}${brByHeadOfc}${brIdByHeadOfc}${detail}${accHeadId}${fromDate}`
    )
    .catch(handleError);
}

export function fetchLedgerParent(filters) {
  const accHeadId = filters?.accHeadId
    ? `&parentAccHeadId=${filters.accHeadId}`
    : "";
  const fromDate = filters?.fromDate
    ? `&fromDate=${formatToUnix(filters.fromDate)}`
    : "";

  const detail = `&detail=true`;

  const brByHeadOfc = "&brByHeadOfc=false";
  const brIdByHeadOfc = "&brIdByHeadOfc=1";

  return axiosInstance()
    .get(
      `/transactions/account/journal-vouchers/ledger/parent?toDate=${formatToUnix(
        filters?.toDate
      )}${brByHeadOfc}${brIdByHeadOfc}${detail}${accHeadId}${fromDate}`
    )
    .catch(handleError);
}

// fetch account balance
export function fetchAccountBalance(accHeadId) {
  return axiosInstance()
    .get(
      `/transactions/account/journal-vouchers/ledger/current-balance?accHeadId=${accHeadId}`
    )
    .catch(handleError);
}
