import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import InsideHeader from "../../layout/InsideHeader";
import FilterForm from "./FilterForm";
import ViewProfitLoss from "./ViewTable";
import { motion } from "framer-motion";

function ProfitLoss() {
  const [profitLoss, setProfitLoss] = useState([]);
  const [dates, setDates] = useState({ from: "", to: "" });

  return (
    <div>
      <InsideHeader title="Profit and Loss">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link color="inherit" href="/">
            Report
          </Link>
          <Typography color="textPrimary">Profit & Loss</Typography>
        </Breadcrumbs>{" "}
      </InsideHeader>
      <motion.div
        initial={{ opacity: 0, x: "-55%", scale: 0 }}
        animate={{
          scale: 1,
          opacity: 1,
          x: 0,
        }}
        transition={{ duration: 0.5 }}
      >
        <FilterForm setProfitLoss={setProfitLoss} setDates={setDates} />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: "-55%", scale: 0 }}
        animate={{
          scale: 1,
          opacity: 1,
          x: 0,
        }}
        transition={{ duration: 0.5 }}
      >
        <ViewProfitLoss profitLoss={profitLoss} dates={dates} />
      </motion.div>
    </div>
  );
}

export default ProfitLoss;
