import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import InsideHeader from "../../layout/InsideHeader";
import CreateReceipt from "./CreateReceipt";
import ViewReceipt from "./ViewReceipt";

function Receipt() {
  const [receipts, setReceipts] = useState([]);
  const [loading, setLoading] = useState(false);
  return (
    <div>
      <InsideHeader title="Receipt">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link color="inherit" href="/">
            Home
          </Link>

          <Typography color="textPrimary">Receipt</Typography>
        </Breadcrumbs>{" "}
      </InsideHeader>
      <CreateReceipt setLoading={setLoading} loading={loading} />
      {/* <ViewReceipt
        account={receipts}
        setAccount={setReceipts}
        loading={loading}
      /> */}
    </div>
  );
}

export default Receipt;
