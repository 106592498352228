import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import InsideHeader from "../../layout/InsideHeader";
import CreatePayment from "./CreatePayment";

function Payment() {
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  return (
    <div>
      <InsideHeader title="Payment">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link color="inherit" href="/">
            Home
          </Link>

          <Typography color="textPrimary">Payment</Typography>
        </Breadcrumbs>{" "}
      </InsideHeader>
      <CreatePayment setLoading={setLoading} />
      {/* <ViewPayment account={account} setAccount={setAccount} /> */}
    </div>
  );
}

export default Payment;
